/**
 * @file Contains Users store.
 */

import { action } from 'mobx';
import UsersService from '_common/services/users.service';

class UsersStore {
	/**
	 * Get users roles.
	 */
	@action
	getUsersRoles = async () => {
		try {
			return await UsersService.getUsersRoles();
		} catch (e) {
			throw new Error(e);
		}
	};

	/**
	 * Get users.
	 *
	 * @async
	 * @function - getMerchantUsers.
	 * @param {string} id - merchant id.
	 */
	@action
	getMerchantUsers = async (id) => {
		try {
			return UsersService.getMerchantUsers(id);
		} catch (e) {
			throw new Error(e);
		}
	};

	/**
	 * Invite user.
	 *
	 * @async
	 * @function - inviteUsersData.
	 * @param {string} id - merchant id.
	 * @param {Object} data - users data.
	 * @param {string} twoFactorAuthToken - two factor auth token.
	 */
	@action
	inviteUsersData = async (id, data, twoFactorAuthToken) => {
		try {
			return await UsersService.inviteUser(id, data, twoFactorAuthToken);
		} catch (e) {
			throw e;
		}
	};

	/**
	 * Edit users data.
	 *
	 * @async
	 * @function - editUsersData.
	 * @param {string} id - merchant id.
	 * @param {Object} data - users data.
	 * @param {string} twoFactorAuthToken - two factor auth token.
	 */
	@action
	editUsersData = async (id, data, twoFactorAuthToken) => {
		try {
			return await UsersService.editUsersData(id, data, twoFactorAuthToken);
		} catch (e) {
			throw e;
		}
	};
}

export default UsersStore;
