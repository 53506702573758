/**
 * @file
 * Contains Report Service.
 */

import {
  BALANCE_REPORT,
  CSV_REPORTS_LIST,
  DATE_REPORT, DOWNLOAD_CSV,
  DOWNLOAD_REGISTRY_CSV,
  EXPORT_CSV,
  FINANCE_REPORT,
  GET_TRANSACTIONS,
  OATHKEEPER_URL,
  TRANSACTION_LIST,
  USERS_REPORT,
  VIEW_MODE_REGISTRY,
  VIEW_MODE_TRANSACTIONS,
  VIEW_MODE_VOLUMES,
  WEEKLY_REPORT,
	TRANSACTION_REFUND,
	REFUND_RATES,
} from '_common/constants/apiEndpoints.constant';
import { apiClient } from '_common/services/apiClient.service';
import axios from 'axios';

/**
 * Function to join parans of get responce
 * @param params
 * @return {string}
 */
const paramsSerializer = (params) => {
  return Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}=${value.join(',')}`;
      }
      return `${key}=${value}`;
    })
    .join('&');
};

/**
 * Get Weekly Report.
 * @param {string} profileId.
 * Profile id.
 */
const getWeeklyReport = async (profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v2/${WEEKLY_REPORT}`.replace('[<APP_ID>]', profileId);
    return await apiClient.get(url);
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Create a cancel token source
 */
let cancelTokenSource = axios.CancelToken.source();

/**
 * Get Report By Date.
 * @param {object} data.
 * Report By Date Data.
 * @param {string} profileId.
 * Profile id.
 */
const getDateReport = async (data, profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v2/${DATE_REPORT}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.get(url, { params: data });
    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.error('Request canceled:', e.message);
    } else {
      throw new Error(e);
    }
  }
};

/**
 * Cancel prev request function
 */
const cancelGetDateReportPreviousRequest = () => {
  cancelTokenSource.cancel('Previous request canceled.');

  // Create a new cancel token source for future requests
  cancelTokenSource = axios.CancelToken.source();
};


/**
 * Get Report Users.
 * @param {object} data.
 * Report Users Data.
 * @param {string} profileId.
 * Profile id.
 */
const getUsersReport = async (data, profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v2/${USERS_REPORT.replace('[<APP_ID>]', profileId)}`;
    const response = await apiClient.get(url, { params: data });
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get transactions report.
 * @param {object} params.
 * Transactions params data.
 * @param {string} profileId.
 * Profile id.
 */
const getFinanceReport = async (params, profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v2/${FINANCE_REPORT.replace('[<APP_ID>]', profileId)}`;
    const response = await apiClient.get(url, { params });
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get balance report.
 * @param {string} profileId.
 * Profile id.
 */
const getBalanceReport = async (profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v2/${BALANCE_REPORT}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.get(url);
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get transaction list CSV data file.
 * @param {object} data.
 * Transactions data.
 * @param {string} profileId.
 * Profile id.
 */
const getTransactionListCsv = async (data, profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v2/${TRANSACTION_LIST}${EXPORT_CSV}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.post(url, data);
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get transaction list CSV data file V2.
 * @param {object} params.
 * Filter Params.
 * @param {string} profileId.
 * Profile id.
 */
const getCreateTransactionCsvReportService = async (params, profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v2/${TRANSACTION_LIST}${EXPORT_CSV}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.get(url, { params, paramsSerializer });
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get CSV file using name
 * @param {string} guid
 * @param {string} profileId
 */
const getTransactionCsvV2 = async (profileId, guid) => {
  try {
    const url = new URL(`${OATHKEEPER_URL}/api/v2/${TRANSACTION_LIST}${DOWNLOAD_CSV}`.replace('[<APP_ID>]', profileId));
    const searchParams = new URLSearchParams();
    searchParams.append('guid', guid);

    const link = document.createElement('a');
    link.href = `${url}?${searchParams.toString()}`;
    link.setAttribute('download', 'report.csv');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary link
    link.parentNode.removeChild(link);
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get transactions report.
 * @param {object} data.
 * Transactions data.
 * @param {string} profileId.
 * Profile id.
 */
const getTransactionsReport = async (data, profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v1/${TRANSACTION_LIST}${VIEW_MODE_TRANSACTIONS}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.post(url, data);
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get volumes report.
 * @param {object} data.
 * Transactions data.
 * @param {string} profileId.
 * Profile id.
 */
const getVolumesReport = async (data, profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v1/${TRANSACTION_LIST}${VIEW_MODE_VOLUMES}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.post(url, data);
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get registry report.
 * @param {object} data.
 * Transactions data.
 * @param {string} profileId.
 * Profile id.
 */
const getRegistryReport = async (data, profileId) => {
  try {
    const url = `${VIEW_MODE_REGISTRY}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.post(url, data);
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get transaction list CSV data file.
 * @param {string} id.
 * File id.
 * @param {string} profileId.
 * Profile id.
 */
const getTransactionRegistryCsv = async (id, profileId) => {
  try {
    const url = `${DOWNLOAD_REGISTRY_CSV}/${id}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.get(url);
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Get transactions list.
 * @param {object} data - Request data.
 * @param {string} profileId - Profile id.
 */
const getTransactionsList = async (data, profileId) => {
  const url = `${OATHKEEPER_URL}/api/v2/${TRANSACTION_LIST}${GET_TRANSACTIONS}`.replace('[<APP_ID>]', profileId);
  const response = await apiClient.post(url, data);
  return response?.data;
};

/**
 * Get transactions list.
 * @param {object} params - Request data.
 * @param {string} profileId - Profile id.
 */
const getTransactionsListV2 = async (params, profileId) => {
  const url = `${OATHKEEPER_URL}/api/v2/${TRANSACTION_LIST}${GET_TRANSACTIONS}`.replace('[<APP_ID>]', profileId);
  const response = await apiClient.get(url, { params });
  return response.data;
};

/**
 * Get csv reports list.
 * @param {string} profileId.
 * Profile id.
 */
const getCsvReports = async (profileId) => {
  try {
    const url = `${OATHKEEPER_URL}/api/v2/${TRANSACTION_LIST}${CSV_REPORTS_LIST}`.replace('[<APP_ID>]', profileId);
    const response = await apiClient.get(url);
    return response?.data;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Transaction refund.
 *
 * @param {string} profileId - merchant guid.
 * @param {string} transactionId - transaction guid.
 * @param {Object} data - reason of refund.
 * @param {string} twoFactorAuthToken - two factor auth token.
 */
const transactionRefund = async (profileId, transactionId, data, twoFactorAuthToken) => {
	try {
		const url = TRANSACTION_REFUND.replace('<MERCHANT_GUID>', profileId).replace('<TRANSACTION_ID>', transactionId);
		const response = await apiClient.post(url, data, { headers: { ...(!!twoFactorAuthToken && { 'X-2FA-Token': twoFactorAuthToken }) } });
		return response?.data;
	} catch (e) {
		if (e.message.includes('2FA needed')) {
			throw new Error(JSON.stringify(e.data));
		} else {
			throw new Error(e);
		}
	}
};

/**
 * Transaction refund rates.
 *
 * @param {string} profileId - merchant guid.
 * @param {string} transactionId - transaction id.
 * @param {number} amount - refund amount.
 */
const transactionRefundRates = async (profileId, transactionId, amount) => {
	try {
		const url = TRANSACTION_REFUND.replace('<MERCHANT_GUID>', profileId).replace('<TRANSACTION_ID>', transactionId);
		const response = await apiClient.get(`${url}${REFUND_RATES}`, { params: { amount, } });
		return response?.data;
	} catch (e) {
		throw new Error(e);
	}
};


export default {
  getWeeklyReport,
  getDateReport,
  getUsersReport,
  getTransactionsReport,
  getFinanceReport,
  getBalanceReport,
  getTransactionListCsv,
  getVolumesReport,
  getRegistryReport,
  getTransactionRegistryCsv,
  getTransactionsList,
  getTransactionsListV2,
  getCreateTransactionCsvReportService,
  cancelGetDateReportPreviousRequest,
  getTransactionCsvV2,
  getCsvReports,
	transactionRefund,
	transactionRefundRates,
};
