/**
 * @file
 * Contains API clients service.
 */

import createClient from '_common/services/apiClientFactory.service';
import { OATHKEEPER_URL } from '_common/constants/apiEndpoints.constant';
import { FrontendApi , Configuration } from '@ory/client';
import { handleOathkeeperError } from './error.service';

const pureClient = createClient({
  baseUrl: OATHKEEPER_URL,
});

const apiClient = createClient({
  baseUrl: OATHKEEPER_URL,
});

const oathkeeperClient = createClient({
  baseUrl: OATHKEEPER_URL
})


/**
 * @param {import('axios').AxiosRequestConfig} config 
 */
const oathkeeperClientInterceptorReq = (config) => {
  return {
    ...config,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      ...config.headers,
      'Accept': 'application/json'
    },
  };
};

/**
 * @param {*} e 
 */
const oathkeeperClientInterceptorReqError = (e) => Promise.reject(e)

/**
 * 
 * @param {import('axios').AxiosResponse} response 
 * @returns {import('axios').AxiosResponse}
 */
const oathkeeperClientInterceptorRes = (response) => response

pureClient.interceptors.request.use(oathkeeperClientInterceptorReq, oathkeeperClientInterceptorReqError);
pureClient.interceptors.response.use(oathkeeperClientInterceptorRes, handleOathkeeperError);

apiClient.interceptors.request.use(oathkeeperClientInterceptorReq, oathkeeperClientInterceptorReqError);
apiClient.interceptors.response.use(oathkeeperClientInterceptorRes, handleOathkeeperError);

oathkeeperClient.interceptors.request.use(oathkeeperClientInterceptorReq, oathkeeperClientInterceptorReqError);
oathkeeperClient.interceptors.response.use(oathkeeperClientInterceptorRes, handleOathkeeperError);

const kratosClient = new FrontendApi(
  new Configuration({
    basePath: `${OATHKEEPER_URL}/user`,
    baseOptions: {
      withCredentials: true,
    },
  }),
);

export { pureClient, apiClient, oathkeeperClient, kratosClient };
