/**
 * @file Contains two factor auth confirmation component.
 */

import React from 'react';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import InputElement from '_common/components/Input/InputElement';
import ButtonElement from '_common/components/Button/ButtonElement';
import {
	ConfirmTwoFactorAuthButtonContainer,
	ConfirmTwoFactorAuthContainer,
	ConfirmTwoFactorAuthTitle,
	ConfirmTwoFactorAuthSubTitle,
	ConfirmTwoFactorAuthButtonCancel,
	ConfirmTwoFactorAuthButtonAuthenticate,
} from './Users.style';
import { useTranslation } from 'react-i18next';
import OtpInputElement from '_common/components/OtpInputElement/OtpInputElement';

const TwoFactorAuthConfirmation = (props) => {

	const { isConfirmTwoFactorVisible } = props;

	const { t } = useTranslation();

	return (
		<div>
			<ConfirmTwoFactorAuthTitle>
				{t('user.modal.title.twoFactorAuth')}
			</ConfirmTwoFactorAuthTitle>
			<ConfirmTwoFactorAuthSubTitle>
				{t('user.modal.subTitle.enterTwoFactorAuthCode')}
			</ConfirmTwoFactorAuthSubTitle>
			<ConfirmTwoFactorAuthContainer>
				<FormItemElement
					name="totp"
					validateTrigger="onChange"
					rules={[
						{
							required: true,
							message: t('input.field.required'),
						},
					]}
				>
					<OtpInputElement
						small
						border
						maxLength={6}
						design='light'
						placeholder="Code"
					/>
				</FormItemElement>
				<ConfirmTwoFactorAuthButtonContainer>
					<ConfirmTwoFactorAuthButtonCancel>
						<ButtonElement
							clear='true'
							onClick={() => isConfirmTwoFactorVisible(false)}
						>
							{t('user.modal.button.twoFactorAuth.cancel')}
						</ButtonElement>
					</ConfirmTwoFactorAuthButtonCancel>
					<ConfirmTwoFactorAuthButtonAuthenticate>
						<ButtonElement htmlType="submit">
							{t('user.modal.button.twoFactorAuth')}
						</ButtonElement>
					</ConfirmTwoFactorAuthButtonAuthenticate>
				</ConfirmTwoFactorAuthButtonContainer>
			</ConfirmTwoFactorAuthContainer>
		</div>
	)
};

export default TwoFactorAuthConfirmation;
