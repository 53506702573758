/**
 * Report button menu button component.
 */

import React from 'react';
import { ReportTableMenuButtonContainer, ReportTableDotsContainer } from './ReportTableMenuButton.style';

const ReportTableMenuButton = (props) => {

	const { darkBorder } = props;

	return (
		<ReportTableMenuButtonContainer darkBorder={darkBorder}>
			<ReportTableDotsContainer>
				<div></div>
				<div></div>
				<div></div>
			</ReportTableDotsContainer>
		</ReportTableMenuButtonContainer>
	)
};

export default ReportTableMenuButton;
