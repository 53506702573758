/**
 * @file
 * Contains Otp Input Element component.
 * InputElement Contains next attributes:
 * # icon {string} - set up icon at input.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { OtpInputWrapper, Input } from './OtpInputElement.style';

const OtpInputElement = (props) => {
	const { getRef, design, disabled, border, small, ...rest } = props; // excluded getRef prop. DO NOT DELETE!!!

	/**
	 * Ref to input.
	 * @param {object} ref.
	 * Ref data.
	 */
	const inputRef = (ref) => {
		if (props.getRef) {
			props.getRef(ref);
		}
	};

	/**
	 *
	 */
	const onKeyPressHandler = (e) => {
		const { key } = e;

		if (!/[0-9]/.test(key) && key !== 'Enter') {
			e.preventDefault();
		}
	};

	return (
		<OtpInputWrapper {...rest}>
			<Input
				innerRef={inputRef}
				{...rest}
				onFocus={(e) => (e.target.placeholder = '')}
				onBlur={(e) => (e.target.placeholder = rest.placeholder ? rest.placeholder : '')}
				design={design}
				disabled={disabled}
				border={border}
				small={small}
				inputMode="numeric"
				onKeyPress={(e) => onKeyPressHandler(e)}
			/>
		</OtpInputWrapper>
	);
};

OtpInputWrapper.propTypes = {
	icon: PropTypes.string,
};

export default OtpInputElement;
