import React, { useState } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import Table from '_common/components/TableElement/TableElement';
import {
	RefundTableWrap,
	LineTo,
	LineArrow,
	RefundTableDataId,
	RefundTableDataIdCopyToClipboardContainer,
} from './Reports.style';
import { copyToClipboardUtil } from '_common/utils/copyToClipboard.util';
import SuccessIcon from '_common/assets/renderSvgIcons/Success.icon';
import CopyIcon from '_common/assets/renderSvgIcons/Copy.icon';
import { roundNumberHigh } from '_common/utils/roundNumber.util';

const RefundTable = (props) => {
  const { t, data = {} } = props;
  const { refunds: { payments = [] } = {} } = data;

	/**
	 * Selected transaction id.
	 */
	const [transactionId, setTransactionId] = useState(false);

	/**
	 * Is copied to clipboard.
	 */
	const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  /**
   * String Separator
   */
  const stringSeparator = (string = '', numbers = 32) => {
    const firstPart = string.substring(0, numbers);
    const secondPart = string.substring(numbers);
    return <span>{firstPart}<br/>{secondPart}</span>;
  }

  /**
   * Table columns
   */
  const tableColumns = [
    {
      title: `${t('transaction.table.refund.table.id')}`,
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => (
				<RefundTableDataId>
					<div>
						{record?.id}
					</div>
					<RefundTableDataIdCopyToClipboardContainer
						onClick={() => {
							setTransactionId(record.id);
							copyToClipboardUtil(record.id);
							setIsCopiedToClipboard(true);

							setTimeout(() => {
								setIsCopiedToClipboard(false);
							}, 1000)
						}}
					>
						{(isCopiedToClipboard && transactionId === record.id) ? (
							<SuccessIcon />
						) : (
							<CopyIcon />
						)}
					</RefundTableDataIdCopyToClipboardContainer>
				</RefundTableDataId>
      ),
	    width: 280,
    },
	  {
		  title: t('transaction.table.refund.table.rrn'),
		  dataIndex: 'rrn',
		  key: 'rrn',
	  },
    {
      title: t('transaction.table.refund.table.refundAmount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => {
				const amount = record?.fee ? (record?.amount - record?.fee) : record?.amount;
				
				return (
					<span>{roundNumberHigh(amount)} {(data?.body_currency || ':').split(':')[1]}</span>
				)
      },
    },
    {
      title: t('transaction.table.refund.table.refundFee'),
      dataIndex: 'fee',
      key: 'fee',
      render: (_, record) => (<span>{`${record?.fee ? (Number.isInteger(record?.fee) ? `${record?.fee}.00` : record?.fee) : '0.00'} ${(data?.body_currency || ':').split(':')[1]}`}</span>),
    },
    {
      title: t('transaction.table.refund.table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (<span>{record?.status ? record?.status : ''}</span>),
    },
  ];

  return (
    <RefundTableWrap>
      <LineTo><LineArrow/></LineTo>
        <Table
          columns={
            tableColumns
          }
          dataSource={payments}
          className="refund-table"
          pagination={false}
        />
    </RefundTableWrap>
  );
};

export default compose(withTranslation())(RefundTable);
