/**
 * @file
 * Contain Table Columns Settings Component.
 */
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Popover } from 'antd';
import { ReactComponent as DragIcon } from '_common/assets/icons/dragg-icon.svg';
import { SettingsWrap, Item, CheckboxItem, DragBtn, PopoverHeader, PopoverWrap } from './TableColumnSettings.style';
import LocalStorageService from '_common/services/localStorage.service';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ButtonElement from '_common/components/Button/ButtonElement';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {inject, observer} from 'mobx-react';

const TableColumnsSettings = (props) => {
  const { columnsList, setColumnsSettings, tableTypeKey, generalReportStore } = props;
  const [columns, setColumns] = useState([]);

  /**
   * Set default details for settings
   */
  useEffect(() => {
    const localColumnItems = LocalStorageService.getItem('tableColumnsSettings') || {};
    setColumns(columnsList.filter((item) => item?.key).map((item) => {
      if (Object.keys(localColumnItems)?.length && tableTypeKey in localColumnItems) {
        const localItem = localColumnItems?.[tableTypeKey]?.[item?.key];
        if (!!localItem) {
          return { ...item, ...localItem };
        }
      }
      return { ...item };
    }));

  }, []);

  /**
   * On settings update handler
   * @param data
   */
  const updateSettingsHandler = (data) => {
    const settingsDetails = data?.reduce((acc, item) => ({
      ...acc, [item?.key]: { order: item?.order, hidden: !!item?.hidden },
    }), {});
    setColumnsSettings(settingsDetails);
    const prevLocalState = LocalStorageService.getItem('tableColumnsSettings');
    LocalStorageService.setItem('tableColumnsSettings', { ...prevLocalState , [tableTypeKey]: settingsDetails });
  }

  /**
   * On Drag end handler
   * @type {(function(*): void)|*}
   */
  const onDragEnd = useCallback(
    (result) => {
      const { destination, source } = result;
      if (!destination) return;
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      const updateColumns = columns.map((col) => ({ ...col }));
      const [reorderItem] = updateColumns.splice(source.index, 1);
      updateColumns.splice(destination.index, 0, reorderItem);
      updateColumns.forEach((item, index) => item.order = index);
      setColumns(updateColumns);
      updateSettingsHandler(updateColumns);
    },
    [columns]
  );

  /**
   * On checkbox change handler
   * @type {(function(*): void)|*}
   */
  const checkboxOnChange = useCallback((e) => {
    const checkItem = e?.target?.value;
    const checkState = e.target.checked;
    setColumns((prevState) => {
      const updatedColumns = prevState.map((item) => {
        if (item?.key === checkItem) {
          return { ...item, hidden: !checkState };
        }
        return item;
      });
      updateSettingsHandler(updatedColumns);
      return updatedColumns;
    })

  }, [columns]);

  /**
   * Drag provider
   * @type {function(*): *}
   */
  const provided = useMemo(() => {
    const sortedColumns = columns.filter((item) => item?.key).sort((a, b) => (a.order > b.order ? 1 : -1));

    return (
      (provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {sortedColumns.map((item, index) => (
            <Draggable
              key={item.dataIndex}
              draggableId={item.dataIndex}
              index={index}
            >
              {(provided) => (
                <Item ref={provided.innerRef} {...provided.draggableProps}>
                  <DragBtn {...provided.dragHandleProps}>
                    <DragIcon />
                  </DragBtn>
                  <CheckboxItem
                    value={item?.key}
                    onChange={checkboxOnChange}
                    checked={!item?.hidden}
                  >{(typeof item?.title != 'function')  ? item?.title : item?.titleList}</CheckboxItem>
                </Item>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      ))
  },[columns])

  /**
   * Popover content
   * @return {JSX.Element}
   */
  const content = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction={'vertical'}>
        {provided}
      </Droppable>
    </DragDropContext>
  );

  /**
   * Reset columns handler
   */
  const resetColumns = () => {
    setColumns(columnsList);
    setColumnsSettings({});
    const prevLocalState = LocalStorageService.getItem('tableColumnsSettings');
    delete prevLocalState[tableTypeKey];
    LocalStorageService.setItem('tableColumnsSettings', prevLocalState);
  }

  /**
   * Header
   */
  const headerComponent = () => (
    <PopoverHeader>Customize Columns
      <ButtonElement
        onClick={resetColumns}
      >
        Reset
      </ButtonElement>
    </PopoverHeader>
  );

  return (
    <SettingsWrap>
      <PopoverWrap>
        <Popover
          placement="bottomRight"
          title={headerComponent}
          content={content}
          open={generalReportStore.getColumnsSettingsOpen}
          onOpenChange={(state) => generalReportStore.setColumnsSettingsOpen(state)}
        />
      </PopoverWrap>
    </SettingsWrap>
  );
};

export default compose(
  withTranslation(),
  inject('generalReportStore')
)(observer(TableColumnsSettings));
