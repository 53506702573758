/**
 * @file
 * Contains global styles.
 */

import { createGlobalStyle } from 'styled-components';
import CloseIcon from '_common/assets/icons/close.white.icon.svg';

const GlobalStyle = createGlobalStyle`
  /* Add Avenir font. */
  
  @font-face {
    font-family: 'Avenir';
    src: url('./fonts/Avenir/Avenir-Roman.eot');
    src: local('Avenir Roman'), local('Avenir-Roman'),
        url('./fonts/Avenir/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Avenir/Avenir-Roman.woff2') format('woff2'),
        url('./fonts/Avenir/Avenir-Roman.woff') format('woff'),
        url('./fonts/Avenir/Avenir-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
      font-family: 'Avenir';
      src: url('./fonts/Avenir/Avenir-Light.eot');
      src: local('Avenir Light'), local('Avenir-Light'),
          url('./fonts/Avenir/Avenir-Light.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-Light.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-Light.woff') format('woff'),
          url('./fonts/Avenir/Avenir-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'Avenir Book';
      src: url('./fonts/Avenir/Avenir-BookOblique.eot');
      src: local('Avenir Book Oblique'), local('Avenir-BookOblique'),
          url('./fonts/Avenir/Avenir-BookOblique.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-BookOblique.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-BookOblique.woff') format('woff'),
          url('./fonts/Avenir/Avenir-BookOblique.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Avenir Black Oblique';
      src: url('./fonts/Avenir/Avenir-BlackOblique.eot');
      src: local('Avenir Black Oblique'), local('Avenir-BlackOblique'),
          url('./fonts/Avenir/Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-BlackOblique.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-BlackOblique.woff') format('woff'),
          url('./fonts/Avenir/Avenir-BlackOblique.ttf') format('truetype');
      font-weight: 900;
      font-style: italic;
  }

  @font-face {
      font-family: 'Avenir';
      src: url('./fonts/Avenir/Avenir-Heavy.eot');
      src: local('Avenir Heavy'), local('Avenir-Heavy'),
          url('./fonts/Avenir/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-Heavy.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-Heavy.woff') format('woff'),
          url('./fonts/Avenir/Avenir-Heavy.ttf') format('truetype');
      font-weight: 900;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Avenir';
      src: url('./fonts/Avenir/Avenir-HeavyOblique.eot');
      src: local('Avenir Heavy Oblique'), local('Avenir-HeavyOblique'),
          url('./fonts/Avenir/Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-HeavyOblique.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-HeavyOblique.woff') format('woff'),
          url('./fonts/Avenir/Avenir-HeavyOblique.ttf') format('truetype');
      font-weight: 900;
      font-style: italic;
  }

  @font-face {
      font-family: 'Avenir';
      src: url('./fonts/Avenir/Avenir-MediumOblique.eot');
      src: local('Avenir Medium Oblique'), local('Avenir-MediumOblique'),
          url('./fonts/Avenir/Avenir-MediumOblique.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-MediumOblique.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-MediumOblique.woff') format('woff'),
          url('./fonts/Avenir/Avenir-MediumOblique.ttf') format('truetype');
      font-weight: 500;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Avenir';
      src: url('./fonts/Avenir/Avenir-LightOblique.eot');
      src: local('Avenir Light Oblique'), local('Avenir-LightOblique'),
          url('./fonts/Avenir/Avenir-LightOblique.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-LightOblique.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-LightOblique.woff') format('woff'),
          url('./fonts/Avenir/Avenir-LightOblique.ttf') format('truetype');
      font-weight: 300;
      font-style: italic;
  }

  @font-face {
      font-family: 'Avenir Book';
      src: url('./fonts/Avenir/Avenir-Book.eot');
      src: local('Avenir Book'), local('Avenir-Book'),
          url('./fonts/Avenir/Avenir-Book.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-Book.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-Book.woff') format('woff'),
          url('./fonts/Avenir/Avenir-Book.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'Avenir';
      src: url('./fonts/Avenir/Avenir-Black.eot');
      src: local('Avenir Black'), local('Avenir-Black'),
          url('./fonts/Avenir/Avenir-Black.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-Black.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-Black.woff') format('woff'),
          url('./fonts/Avenir/Avenir-Black.ttf') format('truetype');
      font-weight: 900;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Avenir';
      src: url('./fonts/Avenir/Avenir-Oblique.eot');
      src: local('Avenir Oblique'), local('Avenir-Oblique'),
          url('./fonts/Avenir/Avenir-Oblique.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-Oblique.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-Oblique.woff') format('woff'),
          url('./fonts/Avenir/Avenir-Oblique.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Avenir';
      src: url('./fonts/Avenir/Avenir-Medium.eot');
      src: local('Avenir Medium'), local('Avenir-Medium'),
          url('./fonts/Avenir/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
          url('./fonts/Avenir/Avenir-Medium.woff2') format('woff2'),
          url('./fonts/Avenir/Avenir-Medium.woff') format('woff'),
          url('./fonts/Avenir/Avenir-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
  }

  /* Add Plus Jakarta Sans font. */
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Light.ttf');
    src: local('Plus Jakarta Sans Light'), local('Plus-Jakarta-Sans-Light'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-LightItalic.ttf');
    src: local('Plus Jakarta Sans Light Italic'), local('Plus-Jakarta-Sans-Light-Italic'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Regular.ttf');
    src: local('Plus Jakarta Sans Regular'), local('Plus-Jakarta-Sans-Regular'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-RegularItalic.ttf');
    src: local('Plus Jakarta Sans Regular Italic'), local('Plus-Jakarta-Sans-Regular-Italic'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-RegularItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Medium.ttf');
    src: local('Plus Jakarta Sans Medium'), local('Plus-Jakarta-Sans-Medium'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-MediumItalic.ttf');
    src: local('Plus Jakarta Sans Medium Italic'), local('Plus-Jakarta-Sans-Medium-Italic'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-SemiBold.ttf');
    src: local('Plus Jakarta Sans SemiBold'), local('Plus-Jakarta-Sans-SemiBold'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-SemiBoldItalic.ttf');
    src: local('Plus Jakarta Sans SemiBold Italic'), local('Plus-Jakarta-Sans-SemiBold-Italic'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Bold.ttf');
    src: local('Plus Jakarta Sans Bold'), local('Plus-Jakarta-Sans-Bold'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-BoldItalic.ttf');
    src: local('Plus Jakarta Sans Bold Italic'), local('Plus-Jakarta-Sans-Bold-Italic'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-ExtraBold.ttf');
    src: local('Plus Jakarta Sans ExtraBold'), local('Plus-Jakarta-Sans-ExtraBold'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-ExtraBoldItalic.ttf');
    src: local('Plus Jakarta Sans ExtraBold Italic'), local('Plus-Jakarta-Sans-ExtraBold-Italic'),
    url('./fonts/Plus_Jakarta_Sans/PlusJakartaSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }


    #root {
    white-space: pre-line;
  }
  
  // Overrides of general styles.
  * {
    box-sizing: border-box;
  }
  
  html,
  body {
    padding: 0;
    margin: 0;
    //font-family: 'Avenir', sans-serif;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
  }
  
  body {
    overflow: overlay;
  }

  body::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    background: rgba(255,255,255,0);
  }

  body::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.darkGrey};
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  
  body::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);
    display: none;
  }
  
  a {
    color: ${(props) => props.theme.colors.mainColor};
    &:hover {
      color: ${(props) => props.theme.colors.mainColor};
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }

  .ant-checkbox-wrapper {
    &.checkbox-theme-color {
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${(props) => props.theme.colors.mainColor};
        border-color: ${(props) => props.theme.colors.mainColor};
      }
    }
  }

  input.ant-input,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    box-shadow: none;
    &.ant-input-focused {
      border-color: ${(props) => props.theme.colors.mainColor} !important;
      box-shadow: none;
    }
    &:hover, &:focus {
      border-color: ${(props) => props.theme.colors.mainColor} !important;
      box-shadow: none;
    }
  }
  
  
  .ant-table-column-sorter-up, .ant-table-column-sorter-down {
    &.active {
      color: ${(props) => props.theme.colors.mainColor};
    }
  }
  
  .ant-spin-nested-loading {
    .ant-spin {
      .ant-spin-dot {
        .ant-spin-dot-item {
          background-color: ${(props) => props.theme.colors.mainColor};
        }
      }
    }
  }
  
.custom-date-dropdown {
    .ant-picker-range-wrapper {
      .ant-picker-range-arrow {
        left: 5% !important;
        
        &:before {
          display: none;
        }
        &:after {
          content: '';
          position: absolute;
          background: ${(props) => props.theme.colors.extraLightGrey};
          border: 1px solid ${(props) => props.theme.colors.darkGrey};
          width: 10px;
          height: 10px;
          top: 1px;
          border-left-width: 0;
          border-top-width: 0;
        }
      }
      .ant-picker-panel-container {
        background: ${(props) => props.theme.colors.extraLightGrey};
        border-radius: 10px;
        border: 1px solid ${(props) => props.theme.colors.darkGrey};
        padding: 12px 25px;
  
        .ant-picker-panels {
          font-size: 12px;
          
          .ant-picker-header {
            .ant-picker-year-btn,
            .ant-picker-month-btn {
              &:hover {
                color: ${(props) => props.theme.colors.mainColor};
              }
            }
          }
          .ant-picker-body {
            .ant-picker-cell {
              .ant-picker-cell-inner {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected 
    .ant-picker-cell-inner, 
    .ant-picker-cell-in-view.ant-picker-cell-range-start 
    .ant-picker-cell-inner, 
    .ant-picker-cell-in-view.ant-picker-cell-range-end 
    .ant-picker-cell-inner {
      background: ${(props) => props.theme.colors.mainColor};
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: none;
    }

    &.with-today {
      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: 1px solid ${(props) => props.theme.colors.mainColor} !important;
      }
    }

  .ant-picker-cell-today {
    .ant-picker-cell-inner {
      &:before {
        border-radius: 50%;
      }
    }
  }
}
  
  .custom-filter-date-input {
    .ant-picker-input {
      input {
        font-size: 16px;
      }
    }
  }
  
  .filter-search-btn {
    font-size: 12px !important;
    line-height: 28px;
    width: 80px !important;
    padding: 0;
    border-radius: 5px !important;
  }
  
   .custom-dropdown {
     margin: 0;
     padding: 0 !important;
     top: 220px;
     border-radius: 10px !important;
     box-shadow: none;
     border: 1px solid ${(props) => props.theme.colors.darkGrey};
     background: ${(props) => props.theme.colors.extraLightGrey};
		   
		   
		 &.light {
				background: ${(props) => props.theme.colors.white} !important;
				text-align: left !important; 
				
				&:hover {
      	  background: ${(props) => props.theme.colors.white} !important;
				}

        .ant-select-item-option-content {
		      text-align: left !important;
        }
		 }

     .ant-select-item-option-content {
       color: ${(props) => props.theme.colors.dark};
       font-weight: 500;
       text-align: center;
       font-size: 12px;
       z-index: 1;

       svg {
         position: absolute;
         left: 17px;
         top: 50%;
         transform: translateY(-50%);
         height: 18px;
       }
     }

     .ant-select-item {
       padding: 10px 10px 10px 20px;
       font-size: 12px;
       position: relative;
       color: ${(props) => props.theme.colors.darkLight};
     }

     .ant-select-item-group {
       color: ${(props) => props.theme.colors.grey};
     }
     
     .ant-select-item-option-active,
     .ant-select-item-option-selected {
       position: relative;
       background: ${(props) => props.theme.colors.mainColorLight};
     }
     
     &.type {
       .ant-select-item {
         &:first-child {
           .ant-select-item-option-content {
             svg {
               height: auto;
               width: 23px;
             }
           }
         }
       }
     }
   }
   
  .tree-select-custom-dropdown {
    position: relative;
    border-radius: 10px !important;
    width: auto;
    padding: 0;
    border: 1px solid ${(props) => props.theme.colors.darkGrey};
    background: transparent;
    box-shadow: none;
		  
		&.light {
      &.ant-select-dropdown-empty {
		    background-color: ${(props) => props.theme.colors.white} !important;
      }

      .ant-select-tree {
        background-color: ${(props) => props.theme.colors.white} !important;
      }
		}  
    
    &.ant-select-dropdown-empty {
      background-color: ${(props) => props.theme.colors.extraLightGrey};
    }

    .ant-select-tree {
      background-color: ${(props) => props.theme.colors.extraLightGrey};
      .ant-select-tree-node-content-wrapper {
        
        .ant-select-tree-iconEle {
          position: absolute;
          left: -10px;
          
          @media (min-width: 1140px) {
            position: static;
            left: 0;
            margin-right: -16px;
          }
          
          svg {
            width: 12px;
            height: 12px;
            
            @media (min-width: 1140px) {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
      padding: 0;
      position: relative;
      
      &.ant-select-tree-treenode-disabled {
        .ant-select-tree-node-content-wrapper {
          background: #F7F7F7;
          &:hover {
            background: #F7F7F7;
          }
        }
        
        .ant-select-tree-title {
          color: #A4A6B3;
        }
      }
      
      &:hover {
        .ant-select-tree-node-content-wrapper {
          background-color: ${(props) => props.theme.colors.mainColorLight};
        }
      }
      
      .ant-select-tree-node-content-wrapper {
        position: relative;
        font-size: 12px !important;
        font-weight: 500;
        padding: 10px 10px 10px 20px !important;
        color: ${(props) => props.theme.colors.dark} !important;

        &:hover {
          background: transparent;
        }
        
        &.ant-select-tree-node-selected {
          background-color: ${(props) => props.theme.colors.mainColorLight};
        }

        &:first-child {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -40px;
            width: 40px;
            height: 40px;
            background: red;
          }
        }
      }  
    }
    
    .ant-select-tree-title {
      font-size: 12px;
      font-weight: 500;
    }
    
    .ant-select-tree-switcher {
     position: absolute;
     top: 10px;
     right: 0;
     z-index: 999;
     transition: transform .2s ease;
     
     &:before {
      content: '';
      position: absolute;
      top: 20px;
      right: 0;
      width: 40px;
      height: 100%;
     }
    }
   
    .ant-select-tree-treenode-switcher-open {
      .ant-select-tree-switcher {
        transform: rotate(180deg);
      }
    }
    
    //&.assets-dropdown {
    //  width: 500px !important;
    //  .ant-select-tree-title {
    //    margin-left: 5px;
    //  }
    //}
  }
  
  .ant-form-item-explain {
    position: absolute;
    font-size: 12px;
  }
  
  .modal-select-dropdown {
    padding: 20px 0 !important;
    background: ${(props) => props.theme.colors.darkWhite} !important;
    border-radius: 10px !important;
  
    .ant-select-item {
      position: relative;
      font-weight: 500;
      padding: 0 20px !important;
      
      &:hover {
        font-weight: 700;
        
        &:before {
           content: '';
           height: 100%;
           width: 2px;
           position: absolute;
           top: 0;
           left: 0;
           background: ${(props) => props.theme.colors.mainColor};
        }
      }
    }
    
    .ant-select-item-option-content {
      text-align: right;
      border-bottom: 1px solid ${(props) => props.theme.colors.silver};
      padding: 5px 0;
      
      &:last-child {
        border-bottom: none;
      }
      
      span {
        position: absolute;
        left: 20px;
      }
    } 
    
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      position: relative;
      font-weight: 300 !important;
      color: ${(props) => props.theme.colors.mainColor};
      
      &:before {
         content: '';
         height: 100%;
         width: 2px;
         position: absolute;
         top: 0;
         left: 0;
         background: ${(props) => props.theme.colors.mainColor};
      }
    }
  }
  
  .main-table {
    &.tab-registry {
      .ant-table-thead > tr > th {
        &:not(:last-child) {
          &:not(.ant-table-selection-column) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${(props) => props.theme.colors.white};
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.csv-list {
      .ant-table-content table .ant-table-thead tr th.ant-table-cell:nth-child(2) {
        padding-left: 50px;
      }
    }
  }

  .ant-table {
    .ant-table-container {
      &:after {
        box-shadow: none !important;
      }
      .ant-table-content {
        .ant-table-summary {
          background: transparent !important;
        }
      }
      tr.ant-table-expanded-row {
        & > td {
          background: transparent !important;
        }
        &:hover {
          & > td {
            background: transparent !important;
          }
        }
      }

      .ant-table-row-expand-icon {
        &:focus-visible,
        &:hover {
          color: ${(props) => props.theme.colors.mainColor};
        }
      }
    }
  }
  
  .ant-modal-wrap {
    &.modal-center {
      .ant-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 355px;
        padding: 90px 0 41px;

        .ant-modal-header {
          border: none;
          padding-bottom: 0;
          .ant-modal-title {
            font-size: 18px;
            font-weight: 800;
            line-height: 1;
            color: ${(props) => props.theme.colors.mainColor};
          }
        }
        
        .ant-modal-body {
          padding-top: 8px;
          border: none;
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.2;
          color: ${(props) => props.theme.colors.darkGrey};
          max-width: 450px;
        }
        
        .ant-modal-footer {
          border: none;
          display: flex;
          justify-content: space-between;
          width: 450px;
          margin-top: auto;
        }
      }
      .ant-modal-close {
        top: -50px;
        right: -50px;
        .ant-modal-close-x {
          background: url(${CloseIcon}) center center no-repeat;
          
          & > span {
            display: none;
          }
        }
      }
    }
  }
  
  .kyc-select-dropdown {
    padding: 20px 0 !important;
    background: ${(props) => props.theme.colors.darkWhite} !important;
    border-radius: 10px !important;
  
    .ant-select-item {
      position: relative;
      font-weight: 500;
      padding: 0 20px !important;
      
      &:hover {
       font-weight: 700;
       
       &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 20px;
        height: 20px;
       }
      }
    }
    
    .ant-select-item-option-content {
      border-bottom: 1px solid ${(props) => props.theme.colors.silver};
      padding: 5px 0;
      
      &:last-child {
        border-bottom: none;
      }
      
      span {
        position: absolute;
        left: 20px;
      }
    } 
  }
  
  .kyc-multiple-select-dropdown {
    padding: 20px 0 !important;
    background: ${(props) => props.theme.colors.darkWhite} !important;
    border-radius: 10px !important;
    
      .ant-select-item {
        position: relative;
        font-weight: 500;
        padding: 10px 0;
        width: 90%;
        margin: 0 auto;
        border-bottom: 1px solid ${(props) => props.theme.colors.silver};
        
        &:before {
           content: '';
           position: absolute;
           top: 50%;
           left: 0;
           transform: translateY(-50%);
           width: 17px;
           height: 17px;
           border: 1px solid ${(props) => props.theme.colors.mainColor};
        }
        
        .ant-select-item-option-content {
          margin-left: 24px;
        }
        
        &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
          position: absolute;
          top: 55%;
          transform: translateY(-50%);
          left: 2px;
          color: ${(props) => props.theme.colors.mainColor};
        }
        
        &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: ${(props) => props.theme.colors.darkWhite};
        }
      }
      
    }
  
  .no-bg{
    background: none transparent !important;
  }

  .no-shadow{
    box-shadow: none !important;
    text-shadow: none !important;
  }
  
  .no-padding-sides {
    .ant-input-group-wrapper{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  
  .disable_tabs_click {
    &__true {
      pointer-events: none;
    }
    &__false {
      pointer-events: auto;
    }
  }

  .transactions-dropdown {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;

    .ant-select-item-option{
      border-bottom: 0.5px solid ${(props) => props.theme.colors.darkGrey};
      
    .custom-date-dropdown {
      border-radius: 20px;
    
     .ant-picker-panel-container {
      padding: 14px;
      font-size: 16px;
      background: #000;
       
      }
     }
     }
     
     .ant-picker-range-arrow {
        display: none;
      }
      
      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: 1px solid ${(props) => props.theme.colors.mainColor};
      }
      
      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
      .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
      .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background: ${(props) => props.theme.colors.mainColor};
      }
      
      .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: ${(props) => props.theme.colors.mainColorLight};
      }
    }
      
  .verification-input {
      padding: 20px !important;
  }
  
  .ant-select-dropdown {
    &.merchant-id-select {
      background-color: ${(props) => props.theme.colors.extraLightGrey};
      font-size: 15px;
      font-weight: 400;
      line-height: 1.4;
      border-radius: 10px;
      border: 1px solid ${(props) => props.theme.colors.darkGrey};
      position: relative;
      color: ${(props) => props.theme.colors.black};
      overflow: initial;
      
      &:before {
        content: '';
        position: absolute;
        top: -7px;
        left: 20px;
        display: block;
        width: 20px;
        height: 20px;
        background: ${(props) => props.theme.colors.extraLightGrey};
        transform: rotate(45deg);
        border: 1px solid ${(props) => props.theme.colors.darkGrey};
        border-bottom-width: 0;
        border-right-width: 0;
      }
      &:after {
        content: '';
        position: absolute;
        top: -5px;
        left: 20px;
        display: block;
        width: 1px;
        height: 1px;
        transform: rotate(45deg);
        border: 10px solid ${(props) => props.theme.colors.mainColor};
        border-bottom-color: transparent;
        border-right-color: transparent;
      }
      
      .ant-select-item {
        font-size: 15px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.black};
        padding: 6px 9px;
        line-height: 1.4;
	      background: transparent;
        
        &.ant-select-item-option-selected {
          font-weight: 600;
          font-size: 15px;
          position: relative;
          background: transparent;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: .5;
            background-color: ${(props) => props.theme.colors.mainColor};
            z-index: 1;
          }
          
          .ant-select-item-option-content {
            z-index: 2;
          }
        }
      }
    }
  }
  
  .ant-notification {
    .ant-notification-notice {
      box-shadow: none !important;
      border-radius: 12px !important;
      border: 1px solid ${(props) => props.theme.colors.lightGrey};
      background-color: ${(props) => props.theme.colors.extraLightGrey};

      .ant-notification-notice-close-x {
        .ant-notification-notice-close-icon {
          border: 1px solid  ${(props) => props.theme.colors.black};
          border-radius: 50%;
          padding: 2px;
          
          svg {
            fill: ${(props) => props.theme.colors.black};
          }
        }
      }
      .ant-notification-notice-content {
        .ant-notification-notice-message {
          font-size: 13px;
          font-weight: 500;
          line-height: 1.4;
        }
      }
    }
  }

  .ant-dropdown-menu {
		 padding: 0; 
		 border-radius: 10px;
		 background-color: ${(props) => props.theme.colors.extraLightGrey};
  }
  
  .ant-dropdown-menu-item:hover,ant-dropdown-menu-item,ant-dropdown-menu-item-active {
		 background: none;
		 color: ${(props) => props.theme.colors.mainColor};
  }
  
  .refund-table {
    .ant-table {
      background: transparent !important;
      
      .ant-table-container {
        table {
          border-collapse: collapse;
          
          & > thead.ant-table-thead {
            & > tr {
              border-radius: 0;
              & > th {
                border-radius: 0 !important;
                border: none;
                background: ${(props) => props.theme.colors.iconColor};
                font-size: 10px;
                font-weight: 700;
                min-width: 95px;
                padding: 0 15px;
                
                &:before {
                  display: none;
                }
              }
            }
          }
  
          & > tbody.ant-table-tbody {
            &:before {
              display: none;
            }
            tr.ant-table-row {
              background: transparent !important;
              border-bottom: 1px solid ${(props) => props.theme.colors.backgroundDarkMain};
              
              & > td {
                padding: 12px 0;
              }
            }
          }
        }
      }
    }
  }

  .otp-input {
    border: 2px solid ${(props) => props.theme.colors.mainColor};
    color: ${(props) => props.theme.colors.dark};
    font-size: 24px;
	  font-weight: 700;
    line-height: 32px;
    width: 50px !important;
    height: 50px;
    margin-right: 9px;
    border-radius: 8px;
    outline: none !important;
	  
	  &.w-40 {
		  width: 40px !important;
	  }
	  
	  &.error {
      border: 2px solid ${(props) => props.theme.colors.red};
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.dark};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .ant-modal-content {
	  border-radius: 15px;
  }
  
  .refund-amount {

    &.ant-tooltip-placement-top, &.ant-tooltip-placement-topLeft, &.ant-tooltip-placement-topRight {
      padding-bottom: 0 !important;
    }
	  
	  .ant-tooltip-content {
      border-radius: 8px !important;
		  
      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
	        &::before {
		        background: ${(props) => props.theme.colors.mainColor};
	        }
        }
      }
		  
		  .ant-tooltip-inner {
			  background: ${(props) => props.theme.colors.mainColor} !important;
			  color: ${(props) => props.theme.colors.black} !important;
			  border-radius: 8px !important;
			  text-align: center !important;
			  padding: 10px !important;
			  min-width: 119px !important;
        font-size: 8px !important;
        font-weight: 400 !important;
        line-height: 12px !important;
      }
	  }

    .ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
      border-bottom-color: ${(props) => props.theme.colors.mainColor} !important;
    }
  }
`;

export default GlobalStyle;
