/**
 * @file
 * Contains of API endpoints.
 */
export const WALLET_HOST = process.env.REACT_APP_WALLET_API_URL || '';
export const SECONDARY_APP_ID = process.env.REACT_APP_SECONDARY_ID || '';
export const OATHKEEPER_URL = process.env.REACT_APP_OATHKEEPER_URL || '';

const API_V1 = 'api/v1';

export const TRANSACTIONS = '/transactions';
export const USERS = '/users';
export const SUMMARY = '/summary';
export const TRANSACTIONS_DICTIONARIES = `[<APP_ID>]/transactions/dictionaries`;
export const WEEKLY_REPORT = `[<APP_ID>]/weekly-report`;
export const DATE_REPORT = `[<APP_ID>]${TRANSACTIONS}${SUMMARY}`;
export const USERS_REPORT = `[<APP_ID>]${USERS}${SUMMARY}`;
export const TRANSACTION_LIST = `[<APP_ID>]/transaction-list`;
export const EXPORT_CSV = `/export/csv`;
export const DOWNLOAD_CSV = `/export/csv/report`;
export const CSV_REPORTS_LIST = `/export/csv/list`;
export const VIEW_MODE_TRANSACTIONS = `/view_mode/transactions`;
export const GET_TRANSACTIONS = `/transactions`;
export const VIEW_MODE_VOLUMES = `/view_mode/volumes`;
export const VIEW_MODE_REGISTRY = `[<APP_ID>]/report/list`;
export const DOWNLOAD_REGISTRY_CSV = `[<APP_ID>]/report/download`;
export const FINANCE_REPORT = `[<APP_ID>]/finance`;
export const BALANCE_REPORT = `[<APP_ID>]/balance`;
export const PAYMENT_METHODS = `[<APP_ID>]/transaction-methods`;
export const COUNTRY_LIST = `[<APP_ID>]/countries`;
export const STATUS_LIST = `[<APP_ID>]/transaction-status`;

export const PROFILE = `${OATHKEEPER_URL}/${API_V1}/profile`;
export const MERCHANT = `${OATHKEEPER_URL}/${API_V1}/merchant`;
export const QUESTIONARY = `${WALLET_HOST}/${SECONDARY_APP_ID}/profile/questionary`;
export const DOWNLOAD_MERCHANT_DOCUMENTATION = `${OATHKEEPER_URL}/${API_V1}/doc`;
export const MY_ACCESS = `${OATHKEEPER_URL}/${API_V1}/iam/my-access`;
export const ROLES = `${OATHKEEPER_URL}/${API_V1}/iam/roles`;
export const MERCHANT_USERS = `${OATHKEEPER_URL}/${API_V1}/<MERCHANT_GUID>/iam/users`;
export const MERCHANT_USER_ROLES = `${OATHKEEPER_URL}/${API_V1}/<MERCHANT_GUID>/iam/user-roles`;
export const MERCHANT_USER_INVITE = `${OATHKEEPER_URL}/${API_V1}/<MERCHANT_GUID>/iam/invite`;
export const LINK_TWO_FACTOR_AUTH_FLOW_ID = `${OATHKEEPER_URL}/${API_V1}/2fa/link`;
export const TRANSACTION_REFUND = `${OATHKEEPER_URL}/api/v2/transactions/<MERCHANT_GUID>/<TRANSACTION_ID>/refund`;
export const REFUND_RATES = '/rates';
