/**
 * @file
 * Contains Profile Store.
 */

import { action, computed, observable, runInAction } from 'mobx';
import ProfileService from '_common/services/profile.service';
import LocalStorageService from '_common/services/localStorage.service';
import { getUserPermissionsUtil } from '_common/utils/userPermissions.util';

class ProfileStore {
  /**
   * Profile data.
   */
  @observable
  profileData = {};

  /**
   * Selected profile id.
   */
  @observable
  selectedProfileId = '';

  /**
   * Selected profile id.
   */
  @observable
  currentProfilePermissions = [];

	/**
	 * Get access data.
	 */
	@observable
	accessData =  {};

	/**
	 * Merchants ids.
	 */
	@observable
	merchantsIds = [];

	/**
	 * Merchant permissions
	 */
	@observable
	merchantPermissions = [];

  /**
   * Get profile data.
   */
  @computed
  get getProfileData() {
    return this.profileData;
  }

  /**
   * Get selected profile id.
   */
  @computed
  get getSelectedProfileId() {
    return this.selectedProfileId;
  }

  /**
   * Get current profile permissions.
   */
  @computed
  get getCurrentProfilePermissions() {
    return this.currentProfilePermissions;
  }

	/**
	 * Get access data.
	 */
	@computed
	get getAccessData() {
		return this.accessData;
	};

	/**
	 * Get merchants ids.
	 */
	@computed
	get getMerchantsIds() {
		return this.merchantsIds;
	}

	/**
	 * Get merchant permissions.
	 */
	@computed
	get getMerchantPermissions() {
		return this.merchantPermissions;
	}

  /**
   * Get Profile Data Action.
   */
  @action
  getProfileDataAction = async () => {
    try {
      const response = await ProfileService.getProfile();

      runInAction(() => {
	      this.merchantsIds = Object.keys(response.permission);
				this.merchantPermissions = response.permission;
        this.profileData = response;
        const selectedLocalID = LocalStorageService.getItem('selected_id');
        const id = this.selectedProfileId || (this.profileData.permission[selectedLocalID] ? selectedLocalID : Object.keys(this.profileData?.permission)[0]) || Object.keys(this.profileData?.permission)[0];
				this.currentProfilePermissions = this.profileData?.permission[id];
        this.selectedProfileId = id;
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Update Profile Data Action.
   * @param {object} data.
   * Profile data.
   */
  @action
  updateProfileDataAction = async (data) => {
    try {
      await ProfileService.updateProfile(data);
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Clear profile data.
   */
  @action
  clearProfileData = () => {
    this.profileData = {};
  };

  /**
   * Set selected profile id.
   * @param {string} id.
   * Profile id.
   */
  @action
  setSelectedProfileId = (id) => {
    this.selectedProfileId = id;
    this.currentProfilePermissions = this.merchantPermissions[id];
  };

	/**
	 * Set access data.
	 */
	@action
	setAccessData = async () => {
		try {
			const response = await ProfileService.getAccessData();

			runInAction(() => {
				this.merchantsIds = response.access.map((access) => access.merchant);

				this.merchantPermissions = response?.access.reduce((obj, acc) => {
					const permissions = acc?.permissions ? getUserPermissionsUtil(acc?.permissions) : [];

					obj[acc.merchant] = [
						...acc?.legacy_permissions ? acc?.legacy_permissions : [],
						...(acc?.permissions && permissions.length) ? permissions : [],
						...(acc?.roles && acc?.roles?.length) ? acc?.roles : [],
					];
					return obj;
				}, {});

				this.accessData = response;
				const selectedLocalID = LocalStorageService.getItem('selected_id');
				const filteredPermissionById = response.access.filter((access) => access.merchant === selectedLocalID && access);
				const id = this.selectedProfileId || (!!filteredPermissionById?.length ? selectedLocalID : response.access[0].merchant) || response.access[0].merchant;

				const currentProfile = response.access.filter((access) => {
					if (access.merchant === id) {
						return access;
					}
				});

				const	currentPermissions = currentProfile[0]?.permissions ? getUserPermissionsUtil(currentProfile[0]?.permissions) : [];

				this.currentProfilePermissions = [
					...currentProfile[0].legacy_permissions ? currentProfile[0].legacy_permissions : [],
					...(currentProfile[0].permissions && currentPermissions) ? currentPermissions : [],
					...(currentProfile[0]?.roles && currentProfile[0]?.roles?.length) ? currentProfile[0]?.roles : [],
				];
				this.selectedProfileId = id;
			});
			return response;
		} catch (e) {
			throw new Error(e);
		}
	};
}

export default ProfileStore;
