/**
 * @file
 * Contains Dashboard filters component.
 */
import React, { useState, useEffect } from 'react';
import { Form, Select, notification } from 'antd';
import DatePickerElement from '_common/components/DatePicker/DatePickerElement';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import Utils from '_common/utils';
import SelectElement from '_common/components/Select/SelectElement';
import themeConfig from 'config';
import { DashboardFiltersWrapper } from './Dashboard.style';
import TransactionTypeIcon from '_common/assets/renderSvgIcons/TrasnactionTypeIcon';
import TransactionInIcon from '_common/assets/renderSvgIcons/TransactionInIcon';
import TransactionOutIcon from '_common/assets/renderSvgIcons/TransactionOutIcon';
import CalendarFilledIcon from '_common/assets/renderSvgIcons/CalendarFilledIcon';
import { FiltersRow, FilterColumn } from 'globalStyles/globalView.style';
import { intervalToDuration } from 'date-fns';
import usePermissions from '../_common/hooks/usePermissions.hook';

const DashboardFilters = (props) => {
  const { t, generalReportStore, dashboardStore, profileStore } = props;

  const { timeFormatStartOfDay, timeFormatEndOfDay, timeFormatByTimestamp } = Utils;

  const { Option } = Select;

  /**
   * Select Date From Store.
   */
  const [selectedDateFrom, setSelectedDateFrom] = useState(0);

  /**
   * Select Date To Store.
   */
  const [selectedDateTo, setSelectedDateTo] = useState(0);

  /**
   * Select Type Store.
   */
  const [selectedType, setSelectedType] = useState('all');

  /**
   * Check Today Date.
   */
  const [todayDate, setTodayDate] = useState(false);

  /**
   * Use form object;
   */
  const [form] = Form.useForm();

  /**
   * users summary flag.
   */
  const [usersSummary] = usePermissions(['merchant.finances.dashboard.users.read']);

  /**
   * Set current date.
   */
  useEffect(() => {
    const dateFrom = timeFormatStartOfDay();
    const dateTo = timeFormatEndOfDay();

    setSelectedDateFrom(dateFrom);
    setSelectedDateTo(dateTo);
    setTodayDate(true);
    return() => {
      setSelectedDateFrom(0);
      setSelectedDateTo(0);
      dashboardStore.setFiltersData('');
    }
  }, []);

  /**
   * Fetch Data Report.
   */
  useEffect(() => {
    (async () => {
      if (selectedType || (selectedDateFrom && selectedDateTo)) {
        const data = {
          date_from: selectedDateFrom,
          date_to: selectedDateTo,
          // tx_type: selectedType,
        };

        const filtersData = {
          dateFrom: timeFormatByTimestamp(selectedDateFrom),
          dateTo: timeFormatByTimestamp(selectedDateTo),
          // type: selectedType,
        };

        dashboardStore.setFiltersData(filtersData);

        try {
          generalReportStore.setIsLoading(true);
          if (selectedDateFrom > 0 && selectedDateTo > 0) {
            const profileId = profileStore.getSelectedProfileId;
            generalReportStore.setDateReportData();
            await generalReportStore.getDateReportDataAction(data, profileId);
            // !!usersSummary && await generalReportStore.getUsersReportDataAction(data, profileId);
          }
        } catch (e) {
          console.error(e);
        } finally {
          generalReportStore.setIsLoading(false);
        }
      }
    })();
  }, [selectedType, selectedDateFrom, selectedDateTo, profileStore.getSelectedProfileId]);

  /**
   * On form change.
   * @param {object} changedValues.
   * Changed field values.
   */
  const onValuesChangeHandler = (changedValues) => {
    const { date, type } = changedValues;

    if (type) {
      setSelectedType(type);
      dashboardStore.setTransactionTypeAction(selectedType);
    }

    if (date) {
      const from = timeFormatStartOfDay(date[0]);
      const to = timeFormatEndOfDay(date[1]);

      const {
        days,
        hours,
        minutes,
        months,
        seconds,
        years,
      } = intervalToDuration({ start: from * 1000, end: to * 1000 });

      if (years > 1 || months > 3 || (months === 3 && days > 5 )) {
        notification.warning({
          message: t('notification.warning.dateSelection.title'),
          description: t('notification.warning.dateSelection.description'),
          duration: 5,
        });
      } else {
        setSelectedDateFrom(from);
        setSelectedDateTo(to);

        const checkFrom = timeFormatStartOfDay();
        const checkTo = timeFormatEndOfDay();

        if (timeFormatStartOfDay(date[0]) === checkFrom && timeFormatEndOfDay(date[1]) === checkTo) {
          setTodayDate(true);
        } else {
          setTodayDate(false);
        }
      }
    }
  };

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  return (
    <DashboardFiltersWrapper>
      <Form
        initialValues={{
          date: [
            timeFormatStartOfDay(dashboardStore.getFiltersData.dateFrom, 'momentObject'),
            timeFormatEndOfDay(dashboardStore.getFiltersData.dateTo, 'momentObject'),
          ],
          type: selectedType,
        }}
        form={form}
        onValuesChange={onValuesChangeHandler}
        layout="vertical"
      >
        <FiltersRow>
          <FilterColumn width={250} pr={12}>
            <FormItemElement name="date">
              <DatePickerElement
                placeholder={[
                  t('dashboard.datePicker.placeholder.dateFrom'),
                  t('dashboard.datePicker.placeholder.dateTo'),
                ]}
                todayDate={todayDate}
                iconComponent={<CalendarFilledIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />}
              />
            </FormItemElement>
          </FilterColumn>
          <FilterColumn width={145} pr={0}>
            <FormItemElement name="type">
              <SelectElement
                placeholder={t('dashboard.select.filter.placeholder.type')}
                iconComponent={
                  (() => {
                    switch (selectedType){
                      case 'all':
                        return <TransactionTypeIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />;
                      case 'deposit':
                        return <TransactionInIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />;
                      case 'withdraw':
                        return <TransactionOutIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />;
                    }
                  })()
                }
                popupClassName="custom-dropdown type"
              >
                <Option value="all">
                  <TransactionTypeIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />
                  {t('dashboard.select.filter.placeholder.type')}
                </Option>
                <Option value="deposit">
                  <TransactionInIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />
                  {t('dashboard.select.filter.in')}
                </Option>
                <Option value="withdraw">
                  <TransactionOutIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />
                  {t('dashboard.select.filter.out')}
                </Option>
              </SelectElement>
            </FormItemElement>
          </FilterColumn>
        </FiltersRow>
      </Form>
    </DashboardFiltersWrapper>
  );
};

export default compose(
  inject('generalReportStore', 'dashboardStore', 'profileStore'),
  withTranslation()
)(observer(DashboardFilters));
