/**
 * @file
 * Contains Settings Service.
 */

import {
  DOWNLOAD_MERCHANT_DOCUMENTATION,
} from '_common/constants/apiEndpoints.constant';
import { apiClient } from '_common/services/apiClient.service';

/**
 * Get Weekly Report.
 * @param {object} data - Data.
 */
const getMerchantDocumentation = async (data) => {
  const { merchantGuid, integrationType } = data;
  const url = `${DOWNLOAD_MERCHANT_DOCUMENTATION}/${merchantGuid}`;
  const body = {
    merchant_guid: merchantGuid,
    integration_type: integrationType,
    version: 3,
  };
  const response = await apiClient.post(url, body);

  return response.data;
}

export default {
  getMerchantDocumentation,
};
