/**
 * @file
 * Contains Header Login Info Component.
 */
import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { HeaderAccountDetails, HeaderLoginInfoWrapper, ProfileIdWrapper } from './Header.style';
import SelectElement from '_common/components/Select/SelectElement';
import { Select } from 'antd';
import LocalStorageService from '_common/services/localStorage.service';

const HeaderLoginInfo = (props) => {
  const { t, profileStore } = props;

  const { Option } = Select;

  /**
   * Render merchant Selector;
   */
  const merchantSelectorRender = () => {
	  const merchantsIds = profileStore.getMerchantsIds;

    /**
     * Set data on select
     */
    const onSelectHandler = (value) => {
      if (typeof value === 'string') {
        profileStore.setSelectedProfileId(value);
        LocalStorageService.setItem('selected_id', value);
      }
    };

    /**
     * Custom filtering for GUID search
     * @param { string } input
     * @param { object } option
     * @return {boolean}
     */
    const filterOption = (input, option) => {
      return option?.value?.toLowerCase().startsWith(input?.toLowerCase());
    };

    if (Object.keys(merchantsIds)?.length === 1) {
      return <ProfileIdWrapper>{profileStore.getSelectedProfileId}</ProfileIdWrapper>;
    }

    return (
      <SelectElement
        value={profileStore.getSelectedProfileId}
        onSelect={onSelectHandler}
        type="simple-text"
        showSearch={merchantsIds?.length > 1}
        filterOption={filterOption}
        popupClassName="merchant-id-select"
        small
      >
        {merchantsIds.map((key) => (
          <Option key={key}>{key}</Option>
        ))}
      </SelectElement>
    );
  };

  return (
    <HeaderLoginInfoWrapper>
      <HeaderAccountDetails>
        <div>{t('header.title')}</div>
        <div>{merchantSelectorRender()}</div>
      </HeaderAccountDetails>
    </HeaderLoginInfoWrapper>
  );
};

export default compose(inject('profileStore'), withTranslation())(observer(HeaderLoginInfo));
