/**
 * @file
 * Contains Reports component view.
 */
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { Tabs } from 'antd';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import themeConfig from 'config';
import MainLayout from '_common/layouts/Main.layout';
import ReportsTable from './ReportsTable';
import ReportsFilters from './ReportsFilters';
import ReportsFiltersV2 from './ReportsFiltersV2';
import { TRANSACTIONS_TABLE_CONFIG, TRANSACTIONS_VIEW_MODE } from '_common/constants/reports.constant';
import TransactionsIcon from '_common/assets/renderSvgIcons/TransactionsIcon';
import VolumesByMethodIcon from '_common/assets/renderSvgIcons/VolumesByMethodIcon';
import StatisticByMethod from '_common/assets/renderSvgIcons/StatisticByMethod';
import TransactionRegistryIcon from '_common/assets/renderSvgIcons/TransactionRegistryIcon';
import usePermissions from '_common/hooks/usePermissions.hook';
import { ReportsContainer, ReportsTableWrapper, TabLabelContainer } from './Reports.style';
import CsvReportsTable from './CsvReportsTable';

const Reports = (props) => {
  const { t, reportStore, generalReportStore, paymentStore, profileStore }= props;
  /**
   * Get url params
   */
  const history = useHistory();
  const { location } = history;
  const { search, pathname } = location;

  /**
   * Report transactions access flag.
   */
  const [reportTransactionsAccess] = usePermissions(['merchant.finances.transactions.read']);

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  /**
   * Transactions view mode mapping.
   */
  const transactionsViewModeMapping = {
    [TRANSACTIONS_VIEW_MODE.transactions]: {
      label: 'transaction.filters.transactions.transactions',
      icon: <TransactionsIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />,
      value: TRANSACTIONS_VIEW_MODE.transactions,
      access: reportTransactionsAccess,
      filter: <ReportsFiltersV2 />,
    },
    [TRANSACTIONS_VIEW_MODE.volumes]: {
      label: 'transaction.filters.transactions.volumes',
      icon: <VolumesByMethodIcon color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />,
      value: TRANSACTIONS_VIEW_MODE.volumes,
      access: reportTransactionsAccess,
      filter: <ReportsFilters />,
    },
    [TRANSACTIONS_VIEW_MODE.statistic]: {
      label: 'transaction.filters.transactions.statistic',
      icon: <StatisticByMethod color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />,
      value: TRANSACTIONS_VIEW_MODE.statistic,
      access: reportTransactionsAccess,
      filter: <ReportsFilters />,
    },
    [TRANSACTIONS_VIEW_MODE.reports]: {
      label: 'transaction.filters.transactions.reports',
      icon: <StatisticByMethod color={theme.colors.iconColor} gradientColor={theme.colors.iconGradientColor} />,
      value: TRANSACTIONS_VIEW_MODE.reports,
      access: reportTransactionsAccess,
      table: <CsvReportsTable />,
    },
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const tab = urlParams.get('tab');
    if(tab && tab !== TRANSACTIONS_VIEW_MODE.transactions ) {
      onChange(tab, false);
    }

    const profileId = profileStore.getSelectedProfileId;
    (async () => {
      try {
        await paymentStore.getTransactionsDictionariesDataAction(profileId);
      } catch (e) {
        console.error(e);
      }
    })();
    return () => {
      reportStore.setTableType(TRANSACTIONS_VIEW_MODE.transactions);
      generalReportStore.setTransactionsCreatedSortType('DESC');
    }
  }, [profileStore.getSelectedProfileId])

  /**
   * On tab change handler
   * @param {string} key
   * @param {boolean} linkUpdate
   */
  const onChange = (key, linkUpdate = true) => {
    reportStore.setTableType(key);
    reportStore.setOrderBy({});
    reportStore.setResultsByValue(TRANSACTIONS_TABLE_CONFIG.defaultResultsByValue);
    generalReportStore.setTransactionsReportData({});
    generalReportStore.setFilterParams({});
    if (linkUpdate) {
      const search = new URLSearchParams({ tab: key })?.toString();
      const historyParams = { pathname, search };
      history.replace(historyParams);
    }
  };

  /**
   * Render Table Tabs
   */
  const renderTabsItems = () => {
    return Object.values(transactionsViewModeMapping).map(({ value, label, access, filter, table }) => {
      if (access) {
        return {
          label: (<TabLabelContainer>{t(label)}</TabLabelContainer>),
          key: value,
          children: (
            <>
              {!!filter && filter }
              <ReportsTableWrapper>
                {!!table ? table : <ReportsTable /> }
              </ReportsTableWrapper>
            </>
          )
        }
      }
      return null;
    }).filter(Boolean);
  }

  return (
    <MainLayout>
      <ReportsContainer>
        <Tabs
          activeKey={reportStore.getTableType}
          items={renderTabsItems()}
          onChange={onChange}
          className={`disable_tabs_click__${reportStore.getIsTableLoading?.toString()}`}
        />
      </ReportsContainer>
    </MainLayout>
)};

export default compose(withTranslation(),inject('reportStore', 'generalReportStore', 'paymentStore', 'profileStore'))(observer(Reports));
