/**
 * @file
 * Contains MainLayout Component.
 */
import React from 'react';
import Sidebar from '_common/components/Sidebar/Sidebar';
import Header from '_common/components/Header/Header.view';
import { MainLayoutContainer, MainContent, SidebarCol, ContentColMain, MainRow } from './MainLayout.style';

const MainLayout = (props) => {
  const { children } = props;

  return (
    <MainLayoutContainer>
      <MainRow>
        <SidebarCol>
          <Sidebar />
        </SidebarCol>
        <ContentColMain>
          <Header />
          <MainContent>{children}</MainContent>
        </ContentColMain>
      </MainRow>
    </MainLayoutContainer>
  );
};

export default MainLayout;
