/**
 * @file Contains table items util.
 */

import React from 'react';
import { getDateFromIso } from '_common/utils/timeConvertor.util';
import MenuVerticalDotsIcon from '_common/assets/renderSvgIcons/MenuVerticalDots.icon';
import { Dropdown } from 'antd';

/**
 * Users table items
 */
export const USERS_TABLE_ITEMS = (
	t,
	usersEditPermissionFlag,
	setSelectedUser,
	items,
	DropdownIconContainer,
	UserTableDataEmail,
	UserTableDataRoles,
) => [
	{
		title: t('user.search.table.title.email'),
		dataIndex: 'email',
		key: 'email',
		render: (text, record) => <UserTableDataEmail>{record.email}</UserTableDataEmail>,
		width: '30%',
	},
	{
		title: t('user.search.table.title.name'),
		dataIndex: 'full_name',
		key: 'full_name',
		width: '30%',
	},
	{
		title: t('user.search.table.title.role'),
		dataIndex: 'roles',
		key: 'roles',
		render: (text, record) => {
			return record.roles.map((role) => <UserTableDataRoles>{role}</UserTableDataRoles>);
		},
		width: '10%',
	},
	{
		title: t('user.search.table.title.created'),
		dataIndex: 'created_at',
		key: 'created_at',
		render: (text, record) => (
			<span>
         <div>{getDateFromIso(record.created_at, 'DD/MM/YYYY')}</div>
         <div>{getDateFromIso(record.created_at, 'HH:mm:ss')}</div>
			</span>
		),
		width: '20%',
	},
	...usersEditPermissionFlag ? [{
		title: '',
		dataIndex: '',
		key: '',
		render: (text, record) => (
			<Dropdown
				menu={{ items }}
				placement="bottomLeft"
				trigger={['click']}
				onClick={() => setSelectedUser(text)}
			>
				<DropdownIconContainer>
					<MenuVerticalDotsIcon />
				</DropdownIconContainer>
			</Dropdown>
		),
		width: '1%',
	}] : [],
];

/**
 * Table columns settings handler
 */
export const columnsSettingsHandler = (data, settings = {}) => {
	if (Object.keys(settings)?.length) {
		return data?.map((column) => {
			if(column?.key in settings) {
				const { order, hidden } = settings?.[column?.key];
				if (!hidden) {
					return { ...column, order };
				}
				return null;
			}
			return column;
		}).filter((item) => item?.key).sort((a, b) => (a.order > b.order ? 1 : -1));
	}
	return data?.map((column) => column?.hidden ? null : column).filter(Boolean);
}
