/**
 * @file
 * Contains Modal Element Component.
 */
import React from 'react';
import { ModalElementWrapper, ModalContent, ModalIcon } from './ModalElement.style';
import ModalButtonElement from './ModalButtonElement';

const ModalElement = (props) => {
  const {
		children,
	  buttonText,
	  buttonType,
	  icon,
	  footer,
	  showModal,
	  showPopup,
	  maskStyle,
	  width,
	  height,
	  closeIcon,
	  destroyOnClose,
	  maskClosable,
	  title,
	} = props;

  return (
    <ModalElementWrapper buttontype={buttonType} icon={icon}>
      <ModalIcon>
        {icon}
      </ModalIcon>
      <ModalButtonElement width={width} buttontype={buttonType} onClick={showPopup}>
        {buttonText}
      </ModalButtonElement>
      <ModalContent
	      title={title}
        buttontype={buttonType}
        maskStyle={maskStyle}
        visible={showModal}
        onCancel={showPopup}
        closeIcon={closeIcon}
        footer={footer}
        height={height}
	      width={width}
	      destroyOnClose={destroyOnClose}
	      maskClosable={maskClosable}
      >
        {children}
      </ModalContent>
    </ModalElementWrapper>
  );
};

export default ModalElement;
