/**
 * @file
 * Contains Otp Input Component Styles.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { Input as AntdInput } from 'antd';

export const Input = styled(({ innerRef, isInvalid, ...rest }) => <AntdInput {...rest} />)`
  &.ant-input {
    padding: ${(props) => props.small ? '4px 14px 4px 20px' : '4px 14px 4px 14px'};
    font-size: ${(props) => props.small ? '12px' : '16px'};
    height: ${(props) => props.small ? '30px' : '65px'};
    box-sizing: border-box;
    animation: all 0.5s ease;
    right: 0;
    border: ${(props) => props.border ? `1px solid ${props.theme.colors.darkGrey}` : 'none'};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.darkGrey}`};
    background: ${(props) => props.design === 'light' ? props.theme.colors.white : props.theme.colors.extraLightGrey};
		border-radius: 5px;

    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none;
    }
		  
		&::placeholder {
		  color: ${(props) => props.theme.colors.darkGrey};
			font-size: ${(props) => props.small ? '12px' : '20px'};
		}
		  
		&:disabled {
			background: ${(props) => props.theme.colors.inputDisabledBackground};
			color: ${(props) => props.theme.colors.inputDisabledColor};
		}

    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      background: ${(props) => props.design === 'light' ? props.theme.colors.white : props.theme.colors.extraLightGrey};
    }
  }
		
	&.ant-input-affix-wrapper {
    padding: ${(props) => props.small ? '0px 14px 0px 20px' : '0px 14px 0px 14px'};
    font-size: ${(props) => props.small ? '12px' : '16px'};
    height: ${(props) => props.small ? '30px' : '65px'};
    box-sizing: border-box;
    animation: all 0.5s ease;
    right: 0;
    border: ${(props) => props.border ? `1px solid ${props.theme.colors.darkGrey}` : 'none'};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.darkGrey}`};
    background: ${(props) => props.design === 'light' ? props.theme.colors.white : props.theme.colors.extraLightGrey};
    border-radius: 5px;
           
    .ant-input {
      font-size: ${(props) => props.small ? '12px' : '16px'};
      height: ${(props) => props.small ? '29px' : '65px'} !important;
		  background: transparent;
		  border: transparent;
    }

    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none;
    }
          
    &::placeholder {
      color: ${(props) => props.theme.colors.darkGrey};
        font-size: ${(props) => props.small ? '12px' : '20px'};
    }
          
    &:disabled {
        background: ${(props) => props.theme.colors.inputDisabledBackground};
        color: ${(props) => props.theme.colors.inputDisabledColor};
    } 
   }	

  &.ant-input:placeholder-shown {
    border-radius: 5px;
    font-size: ${(props) => props.small ? '12px' : '20px'};
    border: 1px solid ${(props) => props.theme.colors.darkGrey};
  }
`;

export const OtpInputWrapper = styled.div`
  position: relative;

  ${(props) =>
	props.icon && css
		`
    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 19px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent url(${props.icon});
      background-size: 100%;
      z-index: 2;
    }`}
`;
