/**
 * @file
 * Contains Payment Service.
 */

import {
  COUNTRY_LIST,
  OATHKEEPER_URL,
  PAYMENT_METHODS,
  STATUS_LIST,
  TRANSACTIONS_DICTIONARIES
} from '_common/constants/apiEndpoints.constant';
import {apiClient} from '_common/services/apiClient.service';

/**
 * Get payment methods.
 * @param {object} params.
 * Params data.
 * @param {string} profileId.
 * Profile id.
 */
const getPaymentMethods = async (params, profileId) => {
    try {
        const url = `${OATHKEEPER_URL}/api/v1/${PAYMENT_METHODS.replace('[<APP_ID>]', profileId)}`
        const response = await apiClient.get(url, {params});
        return response.data;
    } catch (e) {
        throw new Error(e);
    }
};

/**
 * Get country list data.
 * @param {object} params.
 * Params data.
 * @param {string} profileId.
 * Profile id.
 */
const getCountryListData = async (params, profileId) => {
    try {
        const url = COUNTRY_LIST.replace('[<APP_ID>]', profileId);
        const response = await apiClient.post(url, params);
        return response.data;
    } catch (e) {
        throw new Error(e);
    }
};

/**
 * Get status list data.
 */
const getStatusListData = async (params, profileId) => {
    try {
        const url = STATUS_LIST.replace('[<APP_ID>]', profileId);
        const {date_from, date_to} = params;
        const response = await apiClient.post(`${url}?date_from=${date_from}&date_to=${date_to}`, params);
        return response.data;
    } catch (e) {
        throw new Error(e);
    }
};

/**
 * Get Transactions Dictionaries list data.
 */
const getTransactionsDictionariesData = async (profileId) => {
    try {
        const url = `/api/v2/${TRANSACTIONS_DICTIONARIES}`.replace('[<APP_ID>]', profileId);
        return await apiClient.get(url);
    } catch (e) {
        throw new Error(e);
    }
};

export default {
    getPaymentMethods,
    getCountryListData,
    getStatusListData,
    getTransactionsDictionariesData,
};
