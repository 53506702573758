/**
 * @file Contains Refund transaction modal component.
 */

import React, { useState, useEffect } from 'react';
import ModalElement from '_common/components/Modal/ModalElement';
import { compose } from 'recompose';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import TotpInputElement from '_common/components/TotpInputElement/TotpInputElement';
import {
	RefundTransactionInfoContainer,
	RefundTransactionButtonsContainer,
	RefundTransactionTitle,
	RefundTransactionDescription,
	RefundTransactionFeeDescription,
	RefundTransactionFeeDescriptionIconContainer,
	RefundTransactionInfo,
	RefundTransactionInfoTitle,
	RefundTransactionInfoValue,
	RefundTransactionTwoFactorContainer,
	RefundTransactionWrapper,
} from './Reports.style';
import ButtonElement from '_common/components/Button/ButtonElement';
import ReportsEnableTwoFactorAuth from './ReportsEnableTwoFactorAuth';
import { twoFactorAuth } from '_common/utils/twoFacrotAuth.util';
import { useMutation } from '@tanstack/react-query';
import { Form, notification } from 'antd';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import { delayUtil } from '_common/utils/delay.util';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import { REFUND_TRANSACTION_INFO } from '_common/constants/reports.constant';
import RefundSuccess from './RefundSuccess';
import RefundFailed from './RefundFailed';
import { timeFormatByTimestamp } from '_common/utils/timeConvertor.util';
import CopyToClipboardIcon from '_common/assets/renderSvgIcons/CopyToClipboard.icon';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import { roundNumberHigh } from '_common/utils/roundNumber.util';
import AlertIcon from '_common/assets/renderSvgIcons/Alert.icon';

const RefundTransactionModal = (props) => {
	const {
		isModalOpen,
		setIsModalOpen,
		selectedTransactionInfo,
		isEnableTwoFactorAuthVisible,
		reportStore,
		profileStore: { getSelectedProfileId },
		generalReportStore,
		t,
	} = props;

	const [refundForm] = Form.useForm();

	/**
	 * Refund mutation.
	 */
	const refundMutation = useMutation({
		mutationFn: async () => await reportStore.transactionRefund(getSelectedProfileId, selectedTransactionInfo.transaction_id, { reason: 'test' }, twoFactorAuthToken),
		retry: 5,
	});

	/**
	 * Is two factor auth confirm button visible.
	 */
	const [isConfirmTwoFactorAuthVisible, setIsConfirmTwoFactorAuthVisible] = useState(false);

	/**
	 * Two factor auth token.
	 */
	const [twoFactorAuthToken, setTwoFActorAuthToken] = useState('');

	/**
	 * Csfr token.
	 */
	const [csfrToken, setCsfrToken] = useState('');

	/**
	 * Two factor flow id.
	 */
	const [twoFactorFlowId, setTwoFactorFlowId] = useState('');

	/**
	 * Is refund loading
	 */
	const [isRefundLoading, setIsRefundLoading] = useState(false);

	/**
	 * Refund status
	 */
	const [refundStatus, setRefundStatus] = useState('');

	/**
	 * Is copy to clipboard active.
	 */
	const [isCopyToClipboardActive, setIsCopyToClipboardActive] = useState(false);

	/**
	 * Totp errors.
	 */
	const totpCodeError = refundForm.getFieldError('totp');

	/**
	 * Totp field validation error.
	 */
	const [totpValidationError, setTotpValidationError] = useState(false);

	/**
	 * Refund id.
	 */
	const [refundId, setRefundId] = useState('');

	/**
	 * Transaction refund fee data.
	 */
	const [transactionRefundFeeData, setTransactionRefundFeeData] = useState({});

	/**
	 * Transaction refund fee is loading.
	 */
	const [isTransactionRefundFeeLoading, setIsTransactionRefundFeeLoading] = useState(false);

	/**
	 * Get transaction refund fee.
	 */
	useEffect(() => {
		if (isModalOpen && getSelectedProfileId && !!Object.keys(selectedTransactionInfo).length) {
			(async () => {
				setIsTransactionRefundFeeLoading(true);
				try {
					const amount = selectedTransactionInfo.amount_body + selectedTransactionInfo.user_fee;
					const response = await reportStore.getTransactionRefundRates(getSelectedProfileId, selectedTransactionInfo.transaction_id, amount);
					setTransactionRefundFeeData(response);
					setIsTransactionRefundFeeLoading(false);
				}	catch (e) {
					console.error(e);
					setIsTransactionRefundFeeLoading(false);
					setRefundStatus('error');
				}
			})()
		}
	}, [isModalOpen, selectedTransactionInfo, getSelectedProfileId]);

	/**
	 * Transaction refund
	 *
	 * @async
	 * @function - transactionRefund
	 */
	const transactionRefund = async () => {
		setIsRefundLoading(true);
		try {
			await reportStore.transactionRefund(getSelectedProfileId, selectedTransactionInfo.transaction_id, {});
			setIsRefundLoading(false);
		} catch (e) {
			const errorMessage = e.message.replace('Error: ', '');
			const errorData = JSON.parse(errorMessage);
			const twoFactorToken = errorData['2fa_token'];

			if (twoFactorToken) {
				const respHook = await twoFactorAuth(twoFactorToken);

				const { csfrToken, flowId, twoFactorAuthToken } = respHook;

				setTwoFActorAuthToken(twoFactorAuthToken);
				setCsfrToken(csfrToken);
				setTwoFactorFlowId(flowId);

				setIsConfirmTwoFactorAuthVisible(true);
				setIsRefundLoading(false);
			}
		}
	};

	/**
	 * Confirm refund.
	 */
	const confirmRefund = async () => {
		setIsRefundLoading(true);
		try {
			const { totp } = refundForm.getFieldsValue();
			await twoFactorAuth('', twoFactorFlowId, totp, csfrToken, getSelectedProfileId);

			await delayUtil(7000);

			const { id } = await reportStore.transactionRefund(getSelectedProfileId, selectedTransactionInfo.transaction_id, { reason: 'test' }, twoFactorAuthToken);
			setRefundId(id || '');

			setIsConfirmTwoFactorAuthVisible(false);
			refundForm.resetFields();

			notification.success({
				message: 'Refund is successful',
				duration: 5,
			});
			setRefundStatus('success');
		} catch(e) {
			console.error(e);

			if (e.message === 'two_factor_auth_error') {
				refundForm.resetFields(['totp']);
				refundForm.setFields([
					{
						name: 'totp',
						errors: [t('user.error.invalidCode')],
					}
				]);
			} else {
				setRefundStatus('error');
			}
		} finally {
			setIsRefundLoading(false);
		}
	};

	/**
	 * Close modal
	 *
	 * @function - close modal.
	 */
	const closeModal = async () => {
		setTwoFactorFlowId('');
		setCsfrToken('');
		setTwoFActorAuthToken('');
		setIsConfirmTwoFactorAuthVisible(false);
		setIsRefundLoading(false);
		refundForm.resetFields();
		setIsModalOpen(false);
		setTotpValidationError(false);
		setTransactionRefundFeeData({});

		setTimeout(() => {
			setRefundStatus('');
		}, 500);

		if (!!refundStatus) {
			try {
				await loadTransactions();
			} catch (e) {
				console.error(e);
			}
		}
	};

	/**
	 * Copy to clipboard
	 * @function - copyToClipboard.
	 * @param {string} text - text to copy to clipboard
	 */
	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		setIsCopyToClipboardActive(true);

		setTimeout(() => {
			setIsCopyToClipboardActive(false);
		}, 1000);
	};

	/**
	 * Load Transactions list
	 */
	const loadTransactions = async () => {
		const profileId = getSelectedProfileId;
		reportStore.setIsTableLoading(true);
		try {
			return await generalReportStore.getTransactionsListV2(profileId);
		} catch (e) {
			console.error(e);
		} finally {
			reportStore.setIsTableLoading(false);
		}
	};

	return (
		<ModalElement
			showModal={isModalOpen}
			closeIcon={<div></div>}
			footer={null}
			destroyOnClose={true}
			maskClosable={false}
			width={isEnableTwoFactorAuthVisible ? 395 : (!!refundStatus ? 437 : 677)}
		>
			{refundStatus ? (
				refundStatus === 'success' ? (
					<RefundSuccess
						selectedTransactionInfo={selectedTransactionInfo}
						closeModal={closeModal}
						refundId={refundId}
						transactionRefundFeeData={transactionRefundFeeData}
						t={t}
					/>
				) : (
					<RefundFailed closeModal={closeModal} t={t} />
				)
			) : (
				isEnableTwoFactorAuthVisible ? (
						<ReportsEnableTwoFactorAuth
							setIsModalOpen={setIsModalOpen}
						/>
					) : (
						<SpinnerElement spinning={isRefundLoading || isTransactionRefundFeeLoading}>
							<RefundTransactionWrapper>
								<RefundTransactionTitle>
									{t('transaction.modal.refund.confirmRefund')}
								</RefundTransactionTitle>
								<RefundTransactionDescription grey={true}>
									{t('transaction.modal.refund.twoFactorRequired')}
								</RefundTransactionDescription>
								<RefundTransactionDescription mb={25}>
									{t('transaction.modal.refund.initiateRefund')}
								</RefundTransactionDescription>
								<RefundTransactionInfoContainer>
									{Object.entries(REFUND_TRANSACTION_INFO).map(([key, value]) => (
										<RefundTransactionInfo refundTransactionKey={value.key}>
											<RefundTransactionInfoTitle>
												{t(value.title)}
											</RefundTransactionInfoTitle>
											<RefundTransactionInfoValue fontWeight={value?.style?.fontWeight}>
												<div>
													{(value?.currencyKey && value.key === 'amount_body') ?
														`${Number.isInteger(selectedTransactionInfo[value.key] + selectedTransactionInfo.user_fee) ? 
															`${selectedTransactionInfo[value.key] + selectedTransactionInfo.user_fee}.00` : 
															selectedTransactionInfo[value.key] + selectedTransactionInfo.user_fee} ${(selectedTransactionInfo[value.currencyKey] || ':').split(':')[1]}` :
														(key === 'date' ?
															`${timeFormatByTimestamp(selectedTransactionInfo[value.key], 'DD/MM/YYYY')} ${timeFormatByTimestamp(selectedTransactionInfo[value.key], 'HH:mm:ss')}` :
															selectedTransactionInfo[value.key])
													}
												</div>
												{value?.copyToClipboard && (
													<div>
														<TooltipElement visible={isCopyToClipboardActive} placement="top" title={`Copied`}>
															<div onClick={() => copyToClipboard(selectedTransactionInfo[value.key])}>
																<CopyToClipboardIcon />
															</div>
														</TooltipElement>
													</div>
												)}
											</RefundTransactionInfoValue>
										</RefundTransactionInfo>
									))}
								</RefundTransactionInfoContainer>
								{transactionRefundFeeData?.contract_fee > 0 && (
									<RefundTransactionFeeDescription>
										<RefundTransactionFeeDescriptionIconContainer>
											<AlertIcon />
										</RefundTransactionFeeDescriptionIconContainer>
										<div>
											{t('transaction.modal.refund.feeInfo.refundFeeOf')}<span>{`${transactionRefundFeeData?.contract_fee} ${(selectedTransactionInfo?.body_currency || ':').split(':')[1] }`}</span>
											{t('transaction.modal.refund.feeInfo.willBeAppliedToMerchant')}
										</div>
									</RefundTransactionFeeDescription>
								)}
								<Form
									form={refundForm}
									onFinish={() => !isConfirmTwoFactorAuthVisible ? transactionRefund() : confirmRefund(refundForm)}
									validateTrigger='onSubmit'
									onFinishFailed={({ _, errorFields }) => {
										errorFields.map((value) => {
											if (value.name[0] === 'totp') {
												setTotpValidationError(true);
											}
										});
									}}
								>
									{isConfirmTwoFactorAuthVisible && (
										<RefundTransactionTwoFactorContainer>
											<RefundTransactionDescription mb={(totpCodeError.length || totpValidationError) ? 60 : 10}>
												{t('transaction.modal.refund.twoFactorCodeVerification')}
											</RefundTransactionDescription>
											<div>
												<FormItemElement
													name='totp'
													errorDisplay={'refund-top'}
													rules={[
														{
															required: true,
															message: t('input.field.required'),
														},
													]}
												>
													<TotpInputElement
														numInputs={6}
														inputStyle={totpCodeError.length ? 'otp-input error w-40' : 'otp-input w-40'}
													/>
												</FormItemElement>
											</div>
										</RefundTransactionTwoFactorContainer>
									)}
									<RefundTransactionButtonsContainer>
										<ButtonElement
											clear='true'
											height={48}
											onClick={() => closeModal()}
										>
											{t('transaction.modal.refund.button.cancelRefund')}
										</ButtonElement>
										<ButtonElement
											height={48}
											htmlType="submit"
										>
											{isConfirmTwoFactorAuthVisible ? t('transaction.modal.refund.button.confirmRefund') : 'Refund'}
										</ButtonElement>
									</RefundTransactionButtonsContainer>
								</Form>
							</RefundTransactionWrapper>
						</SpinnerElement>
					)
			)}
		</ModalElement>
	)
};

export default compose(
	withTranslation(),
	inject(
		'reportStore',
		'profileStore',
		'generalReportStore',
	),
)(observer(RefundTransactionModal));
