/**
 * @file implements useWhoAmI hook that checks if user is authenticated and if so returns user info
 */

import { useQuery } from '@tanstack/react-query';

import { kratosClient } from '_common/services/apiClient.service';

export const useWhoAmI = () => {
  return useQuery(
    ['whoami'],
    async () => {
      const { data } = await kratosClient.toSession();
      return data;
    },
    {
      retry: 0,
    },
  );
};
