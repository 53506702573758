/**
 * @file Contains integration type component.
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	SettingsLinksList,
	SettingsLinksListItem,
	SettingsLinksListItemLink,
	SettingsLinksListItemTitle,
} from './Settings.style';
import { useMerchantDocumentation } from '_common/hooks/api/useMerchantDocumentation.hook';
import { observer, inject } from 'mobx-react';
import FileSaver from 'file-saver';

const IntegrationType = (props) => {
	const { profileStore } = props;

	const INTEGRATION_TYPE = {
		h2h: 'h2h',
		fpf: 'fpf',
	};

	/**
	 * Use translation.
	 */
	const { t } = useTranslation();

	/**
	 * Loading merchant documentation btn type.
	 */
	const [loadingType, setLoadingType] = useState(null);

	/**
	 * Use merchant documentation query.
	 */
	const {
		mutateAsync: mutateMerchantDocumentation,
	} = useMerchantDocumentation();

	/**
	 * On download merchant documentation handler.
	 * @param {string} integrationType - Integration type.
	 */
	const onDownloadMerchantDocumentationHandler = async (integrationType) => {
		try {
			setLoadingType(integrationType);

			const merchantGuid = profileStore.getSelectedProfileId;
			const document = await mutateMerchantDocumentation({ merchantGuid, integrationType });
			const fileName = `${integrationType}-${merchantGuid}-documentation.html`;
			const file = new File([document], fileName, { type: 'text/plain;charset=utf-8' });
			FileSaver.saveAs(file);
		} catch (e) {
			console.error(e);
		} finally {
			setLoadingType(null);
		}
	}

	return (
		<SettingsLinksList>
			<SettingsLinksListItem>
				<SettingsLinksListItemTitle>
					{t('settings.documentation.h2h.description')}
				</SettingsLinksListItemTitle>
				<SettingsLinksListItemLink
					loading={loadingType === INTEGRATION_TYPE.h2h}
					onClick={() => onDownloadMerchantDocumentationHandler(INTEGRATION_TYPE.h2h)}
				>
					{t('settings.documentation.h2h.link')}
				</SettingsLinksListItemLink>
			</SettingsLinksListItem>
			<SettingsLinksListItem>
				<SettingsLinksListItemTitle>
					{t('settings.documentation.fpf.description')}
				</SettingsLinksListItemTitle>
				<SettingsLinksListItemLink
					loading={loadingType === INTEGRATION_TYPE.fpf}
					onClick={() => onDownloadMerchantDocumentationHandler(INTEGRATION_TYPE.fpf)}
				>
					{t('settings.documentation.fpf.link')}
				</SettingsLinksListItemLink>
			</SettingsLinksListItem>
		</SettingsLinksList>
	)
};

export default inject('profileStore')(observer(IntegrationType));
