/**
 * @file Contains delete user modal.
 */

import React, { useEffect } from 'react';
import ModalElement from '_common/components/Modal/ModalElement';
import CloseModalIcon from '_common/assets/renderSvgIcons/CloseModalIcon';
import SpinnerElement from '_common/components/Spinner/SpinnerElement';
import EnableTwoFactorAuth from './EnableTwoFactorAuth';
import { Form } from 'antd';
import { RevokeModalButtonsContainer, RevokeModalTitle } from './Users.style';
import FormItemElement from '_common/components/FormItem/FormItemElement';
import InputElement from '_common/components/Input/InputElement';
import ButtonElement from '_common/components/Button/ButtonElement';
import TwoFactorAuthConfirmation from './TwoFactorAuthConfirmation';

const DeleteUserModal = (props) => {

	const {
		closeModal,
		selectedUser,
		isModalOpen,
		isLoading,
		isEnableTwoFactorAuthVisible,
		redirectToSettings,
		isConfirmTwoFactorAuthVisible,
		deleteUserHandler,
		confirmTwoFactorAuthForm,
		setIsConfirmTwoFactorAuthVisible,
		confirmTwoFactorAuth,
		t,
		userGuid,
		email,
	} = props;

	const [revokeUserForm] = Form.useForm();

	useEffect(() => {
		if (isModalOpen) {
			revokeUserForm.setFieldsValue({
				user_id: selectedUser.id,
				full_name: selectedUser.full_name,
			});
		}
	}, [isModalOpen]);

	/**
	 * Close modal handler.
	 *
	 * @function - closeModalHandler.
	 */
	const closeModalHandler = () => {
		revokeUserForm.resetFields();
		closeModal();
	};

	return (
		<ModalElement
			showModal={isModalOpen}
			showPopup={() => closeModalHandler()}
			closeIcon={<CloseModalIcon />}
			width={395}
			footer={null}
			destroyOnClose={true}
			maskClosable={false}
		>
			<SpinnerElement
				spinning={isLoading}
			>
				{isEnableTwoFactorAuthVisible ? (
					<EnableTwoFactorAuth redirectFn={() => redirectToSettings()} />
				) : (
					<div>
						{!isConfirmTwoFactorAuthVisible ? (
							<Form
								form={revokeUserForm}
								onFinish={(values) => deleteUserHandler(values)}
							>
								<div>
									<RevokeModalTitle>
										{t('user.search.table.modal.title.revoke', { userGuid, email })}
									</RevokeModalTitle>
									<FormItemElement
										hidden
										name="user_id"
									>
										<InputElement/>
									</FormItemElement>
									<FormItemElement
										hidden
										name="full_name"
									>
										<InputElement/>
									</FormItemElement>
									<RevokeModalButtonsContainer>
										<ButtonElement
											clear='true'
											height={30}
											onClick={() => closeModalHandler()}
										>
											{t('user.search.table.modal.button.cancel')}
										</ButtonElement>
										<ButtonElement
											height={30}
											htmlType="submit"
										>
											{t('user.search.table.modal.button.delete')}
										</ButtonElement>
									</RevokeModalButtonsContainer>
								</div>
							</Form>
						) : (
							<Form
								form={confirmTwoFactorAuthForm}
								onFinish={() => confirmTwoFactorAuth(revokeUserForm)}
								validateTrigger='onSubmit'
							>
								<TwoFactorAuthConfirmation
									isConfirmTwoFactorVisible={setIsConfirmTwoFactorAuthVisible}
								/>
							</Form>
						)}
					</div>
				)}
			</SpinnerElement>
		</ModalElement>
	)
};

export default DeleteUserModal;
