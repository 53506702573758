/**
 * @file
 * Contains Reports Table component view.
 */
import React, { useState, useEffect } from 'react';
import Utils from '_common/utils';
import { Col, Pagination, Row, Table as AntdTable } from 'antd';
import Table from '_common/components/TableElement/TableElement';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { TRANSACTIONS_TABLE_CONFIG, TRANSACTIONS_VIEW_MODE } from '_common/constants/reports.constant';
import themeConfig from 'config';
import {
	PaginationStyle,
	TableSummaryCell,
	TableSummaryRow,
	TableSummaryTransCell,
	TransactionsPaginationButton,
	UserColumn,
	TransactionRegistryDownloadLink,
	TransactionRegistryDownloadLinkText,
	TransactionRegistryDownloadLinkIconWrapper,
	TransactionRegistryDownloadTitle,
	ShowMoreButtonWrapperStyle,
	CancelStatusWrap,
	ReportsTableTitleContainer,
	TableTransactionIdColumn,
	TableTransactionIdColumnContainer,
	TableTransactionIdColumnCopyContainer,
	TableTransactionIdColumnVisibleContainer,
	TableDropdownMenuContainer,
} from './Reports.style';
import PaginationNextArrowIcon from '_common/assets/renderSvgIcons/PaginationNextArrow';
import PaginationPrevArrowIcon from '_common/assets/renderSvgIcons/PaginationPrevArrow';
import DownloadIcon from '../_common/assets/renderSvgIcons/DownloadIcon';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import { format, fromUnixTime } from 'date-fns';
import ButtonElement from '_common/components/Button/ButtonElement';
import { ReactComponent as QuestionMarkCircled } from '_common/assets/icons/question-mark-circled.svg';
import RefundTable from './RefundTable';
import TableColumnsSettings from '_common/components/TableColunmsSettings/TableColumnsSettings';
import LocalStorageService from '_common/services/localStorage.service';
import { columnsSettingsHandler } from '_common/utils/tableItems.util';
import EyeIcon from '_common/assets/renderSvgIcons/Eye.icon';
import SuccessIcon from '_common/assets/renderSvgIcons/Success.icon';
import CopyIcon from '_common/assets/renderSvgIcons/Copy.icon';
import CrossedOutEyeIcon from '_common/assets/renderSvgIcons/CrossedOutEye.icon';
import { copyToClipboardUtil } from '_common/utils/copyToClipboard.util';
import { Dropdown } from 'antd';
import RefundTransactionModal from './RefundTransactionModal';
import { useBrowserSettingsFlow } from '_common/hooks/api/kratos/useSettingsFlow.hook';
import { isTwoFactorAuthEnabled } from '_common/utils/twoFactorAuth.util';
import ReportTableMenuButton from '_common/components/ReportTableMenuButton/ReportTableMenuButton';

const ReportsTable = (props) => {
  const { generalReportStore, t, reportStore, profileStore } = props;

  const { getTableType, setOrderBy } = reportStore;

  const { compareAB, timeFormatByTimestamp } = Utils;

  const { transactionsPerPage, dayFormat, weekFormat, monthFormat } = TRANSACTIONS_TABLE_CONFIG;

	const browserSettingsFlowQuery = useBrowserSettingsFlow(
		'',
		(error) => {
			const { data } = error?.response;

			if (!!data?.error?.id && data?.error?.id === 'session_aal2_required') {
				setIsTwoFactorAuthActive(true);
			}
		},
		false,
	);

  const [columnTypeVolumes, setColumnTypeVolumes] = useState([]);

  /**
   * Transactions list data.
   */
  const [transactionsList, setTransactionsList] = useState([]);

  /**
   * Transactions list data.
   */
  const [transactionsTotal, setTransactionsTotal] = useState([]);

  /**
   * Table Pagination Length State,
   */
  const [paginationLength, setPaginationLength] = useState(0);

  /**
   * Is show more button loading.
   */
  const [isShowMoreBtnLoading, setIsShowMoreBtnLoading] = useState(false);

  /**
   * Fields sort type.
   */
  const [fieldsSortType, setFieldsSortType] = useState({
    created_at: 'ASC',
  });

  /**
   * Columns sort.
   */
  const [columnsSettings, setColumnsSettings] = useState(LocalStorageService.getItem('tableColumnsSettings')?.[getTableType] || {});

	/**
	 * Transaction id visible
	 */
	const [isTransactionIdVisible, setIsTransactionIdVisible] = useState(false);

	/**
	 * Is copied to clipboard.
	 */
	const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

	/**
	 * Selected transaction id.
	 */
	const [transactionId, setTransactionId] = useState(false);

	/**
	 * Selected transaction info.
	 */
	const [selectedTransactionInfo, setSelectedTransactionInfo] = useState({});

	/**
	 * Transaction refund modal open.
	 */
	const [isTransactionRefundModalOpen, setIsTransactionRefundModalOpen] = useState(false);

	/**
	 * Is two factor auth active.
	 */
	const [isTwoFactorAuthActive, setIsTwoFactorAuthActive] = useState(false);

	/**
	 * Is enable two factor auth visible.
	 */
	const [isEnableTwoFactorAuthVisible, setIsEnableTwoFactorAuthVisible] = useState(false);

	/**
	 * Is two factor authentication activation info visible.
	 */
	useEffect(() => {
		(async () => {
			try {
				const browserSettingsResponse = await browserSettingsFlowQuery.refetch();
				const isTwoFactorEnabled = isTwoFactorAuthEnabled(browserSettingsResponse, isTwoFactorAuthActive, browserSettingsResponse.isSuccess);
				setIsEnableTwoFactorAuthVisible(!isTwoFactorEnabled);
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

  /**
   * Get table data.
   */
  useEffect(() => {
    if (generalReportStore.getTransactionsReportData?.data) {
      const resultsBy = reportStore.getResultsByValue;
      let data = generalReportStore.getTransactionsReportData.data.map((item, index) => {
        return { key: item.payment_group + index, ...item };
      });

      if (reportStore.getTableType === 'volumes') {
        const { columns } = generalReportStore.getTransactionsReportData;
        const config = [
          {
            title: t('transaction.table.header.method'),
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            width: 100,
            sorter: true,
            render: (text, record) => record.payment_group,
          },
        ];

        columns.forEach((item) => {
          let titleFormat = timeFormatByTimestamp(Number(item), dayFormat);

          if (resultsBy === 'week') {
            titleFormat = timeFormatByTimestamp(Number(item), weekFormat);
            titleFormat = titleFormat.replace(/([ek])/g, '').split(',', 1);
          }

          if (resultsBy === 'month') {
            titleFormat = timeFormatByTimestamp(Number(item), monthFormat);
          }

          config.push({
            title: titleFormat,
            dataIndex: item,
            key: item,
            width: 80,
            sorter: false,
            render: (text, record) => (record[item] ? Utils.trimNumber(Number(record[item])) : 0),
          });
        });

        setColumnTypeVolumes(config);
      } else if (reportStore.getTableType !== 'transactions') {
        data = generalReportStore.getTransactionsReportData.data.map((item, index) => ({
          key: (item?.transaction_id || 0) + (item?.payment_group || 0) + (item?.created_at || 0) + index,
          ...item,
        }));
      }

      setTransactionsList(data);
    }

    if (generalReportStore.getTransactionsReportData?.transactions && reportStore.getTableType === 'transactions') {
      const data = generalReportStore.getTransactionsReportData.transactions.map((item, index) => {
        return { key: item.payment_group + index, ...item };
      });

      setTransactionsList(data);
    }

    if (generalReportStore.getTransactionsReportData.total) {
      setTransactionsTotal(generalReportStore.getTransactionsReportData.total);
    }
    if (generalReportStore.getTransactionsReportData?.registry) {
      setTransactionsList(generalReportStore.getTransactionsReportData?.registry);
    }
    if (!Object.keys(generalReportStore.getTransactionsReportData || {})?.length) {
      setTransactionsList([])
    }

    //Set pagination
    if (generalReportStore.getTransactionsReportData.data) {
      setPaginationLength(generalReportStore.getTransactionsReportData?.total?.n || 0);
    }
  }, [generalReportStore.getTransactionsReportData]);

  /**
   * Download registry file handler.
   * @param {object} record.
   * File data.
   */
  const downloadRegistryFileHandler = async (record) => {
    try {
      const profileId = profileStore.getSelectedProfileId;
      const dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";
      const params = {
        sort_by: "ASC",
        ...record?.date_from && { date_from: format(record?.date_from, dateFormat) },
        ...record?.date_to && { date_to: format(record?.date_to, dateFormat) },
      }
      generalReportStore.setFilterParams(params);
      await generalReportStore.getTransactionListCsvV2(profileId);
      generalReportStore.setFilterParams({});
    } catch (e) {
      console.error(e);
    }
  };

	/**
	 * Transaction table menu items items.
	 */
	const items = [
		{
			label: <div
				onClick={() => setIsTransactionRefundModalOpen(true)}
			>
				{t('transaction.table.menu.item.transactionRefund')}
			</div>,
			key: 'refund',
		},
	];

  /**
   * Columns by type config.
   */
  const columnsByType = {
    transactions: [
      {
        title: t('transaction.table.header.created'),
        dataIndex: 'created_at',
        key: 'created_at',
        width: '90px',
        ellipsis: true,
        defaultSortOrder: 'descend',
        sorter: (a, b) => a?.created_at - b?.created_at,
        render: (text, record) => <span>
          <div>{timeFormatByTimestamp(record.created_at, 'DD/MM/YYYY')}</div>
          <div>{timeFormatByTimestamp(record.created_at, 'HH:mm:ss')}</div>
        </span>,
        order: 1,
      },
      AntdTable.EXPAND_COLUMN,
      {
        title: t('transaction.table.header.transactionId'),
        dataIndex: 'transaction_id',
        key: 'transaction_id',
        width: isTransactionIdVisible ? '300px' : '160px',
	      render: (text, record) => (
					<TableTransactionIdColumn>
						<TableTransactionIdColumnContainer
							isOpen={(isTransactionIdVisible && transactionId === record.transaction_id)}
						>
							<p>
								{record.transaction_id}
							</p>
						</TableTransactionIdColumnContainer>
						<TableTransactionIdColumnCopyContainer
							onClick={() => {
								setTransactionId(record.transaction_id);
								copyToClipboardUtil(record.transaction_id);
								setIsCopiedToClipboard(true);

								setTimeout(() => {
									setIsCopiedToClipboard(false);
								}, 1000)
							}}
						>
							{(isCopiedToClipboard && transactionId === record.transaction_id) ? (
								<SuccessIcon />
							) : (
								<CopyIcon />
							)}
						</TableTransactionIdColumnCopyContainer>
						<TableTransactionIdColumnVisibleContainer>
							{(isTransactionIdVisible && transactionId === record.transaction_id) ? (
								<div onClick={() => setIsTransactionIdVisible(false)}>
									<CrossedOutEyeIcon />
								</div>
							) : (
								<div
									onClick={() => {
										setTransactionId(record.transaction_id);
										setIsTransactionIdVisible(true);
									}}
								>
									<EyeIcon />
								</div>
							)}
						</TableTransactionIdColumnVisibleContainer>
					</TableTransactionIdColumn>
	      ),
        order: 2,
      },
      {
        title: t('transaction.table.header.externalId'),
        dataIndex: 'merchant_external_id',
        key: 'merchant_external_id',
        width: '100px',
        order: 3,
      },
      {
        title: t('transaction.table.header.externalPayerId'),
        dataIndex: 'merchant_payer_id',
        key: 'merchant_payer_id',
        width: '100px',
        order: 4,
      },
      {
        title: t('transaction.table.header.status'),
        dataIndex: 'state',
        key: 'state',
        width: '80px',
        render: (text, record) => {
          if (record?.state && typeof record?.state === 'string') {
            const str = record?.state?.replaceAll('_', ' ') || '';

            if (record?.state === 'canceled' && !!record?.unified_error_message) {
              return <CancelStatusWrap>
                {str?.charAt(0)?.toUpperCase() + str?.slice(1)}
                <TooltipElement title={record?.unified_error_message}>
                  <QuestionMarkCircled/>
                </TooltipElement>
              </CancelStatusWrap>
            }

            return str?.charAt(0)?.toUpperCase() + str?.slice(1);
          }

          return record?.state || '';
        },
        order: 5,
      },
      {
        title: t('transaction.table.header.method'),
        dataIndex: 'payment_group',
        key: 'payment_group',
        width: '90px',
        render: (text, record) => record.payment_group,
        order: 6,
      },
      {
        title: t('transaction.table.header.card_number'),
        dataIndex: 'card_number',
        key: 'card_number',
        width: '130px',
        hidden: true,
        render: (text, record) => record?.bank_card?.masked_number || '',
        order: 7,
      },
      {
        title: t('transaction.table.header.cardholder'),
        dataIndex: 'holder',
        key: 'holder',
        width: '120px',
        hidden: true,
        render: (text, record) => record?.bank_card?.holder || '',
        order: 8,
      },
      {
        title: t('transaction.table.header.payment_country'),
        dataIndex: 'payment_country',
        key: 'payment_country',
        width: '80px',
        hidden: true,
        render: (text, record) => record?.bank_card?.country || '',
        order: 9,
      },
      {
        title: t('transaction.table.header.type'),
        dataIndex: 'kind',
        key: 'kind',
        width: '65px',
        render: (text, record) => `${record?.kind?.[0].toUpperCase()}${record?.kind?.slice(1)}`,
        order: 10,
      },
        {
          title: t('transaction.table.header.country'),
          dataIndex: 'country',
          key: 'country',
          width: '80px',
          render: (text, record) => {
            return (!!record?.bank_card_country && record?.bank_card_country !== 'bank_card_not_provided_in_fields')
              ? record.bank_card_country
              : (record.country === '' || record.country === undefined)
                ? ''
                : record.country === 'WWC' ? 'Worldwide' : record.country;
          },
          order: 11,
        },
      {
        title: () => (
          <TooltipElement title={t('transaction.table.footer.amountBody.tooltip')}>
	          <ReportsTableTitleContainer>
              {t('transaction.table.footer.amountBody')}
	          </ReportsTableTitleContainer>
          </TooltipElement>
        ),
        titleList: t('transaction.table.footer.amountBody'),
        showSorterTooltip: false,
        dataIndex: 'amount_body',
        key: 'amount_body',
        width: '88px',
        align: 'right',
        render: (text, record) => {
          const currency = (record?.body_currency || ':').split(':')[1];
          return `${Utils.trimAmount(record.amount_body || record.amount)} ${currency}`
        },
        order: 12,
      },
      {
        title: () => (
          <TooltipElement title={t('transaction.table.header.userFee.tooltip')}>
	          <ReportsTableTitleContainer>
              {t('transaction.table.header.userFee')}
	          </ReportsTableTitleContainer>
          </TooltipElement>
        ),
        titleList: t('transaction.table.header.userFee'),
        showSorterTooltip: false,
        dataIndex: 'user_fee',
        key: 'user_fee',
        width: '58px',
        align: 'right',
        render: (text, record) => {
          const currency = (record?.body_currency || ':').split(':')[1];
          return `${Utils.trimAmount(record?.user_fee)} ${currency}`;
        },
        order: 13,
      },
      {
        title: () => (
          <TooltipElement title={t('transaction.table.header.merchantFee.tooltip')}>
	          <ReportsTableTitleContainer>
              {t('transaction.table.header.merchantFee')}
	          </ReportsTableTitleContainer>
          </TooltipElement>
        ),
        titleList: t('transaction.table.header.merchantFee'),
        showSorterTooltip: false,
        dataIndex: 'merchant_fee',
        key: 'merchant_fee',
        width: '88px',
        align: 'right',
        render: (text, record) => {
          const currency = (record?.body_currency || ':').split(':')[1];
          return `${Utils.trimAmount(record?.merchant_fee)} ${currency}`;
        },
        order: 14,
      },
      {
        title: () => (
          <TooltipElement title={t('transaction.table.header.chargedAmount.tooltip')}>
	          <ReportsTableTitleContainer>
              {t('transaction.table.header.chargedAmount')}
	          </ReportsTableTitleContainer>
          </TooltipElement>
        ),
        titleList: t('transaction.table.header.chargedAmount'),
        showSorterTooltip: false,
        dataIndex: 'charged_amount',
        key: 'charged_amount',
        width: '82px',
        align: 'right',
        render: (text, record) => {
          const currency = (record?.charged_currency || ':').split(':')[1];
          return `${Utils.trimAmount(record.charged_amount)} ${currency}`;
        },
        order: 15,
      },
      {
        title: () => (
          <TooltipElement title={t('transaction.table.header.creditedAmount.tooltip')}>
	          <ReportsTableTitleContainer>
              {t('transaction.table.header.creditedAmount')}
	          </ReportsTableTitleContainer>
          </TooltipElement>
        ),
        titleList: t('transaction.table.header.creditedAmount'),
        showSorterTooltip: false,
        dataIndex: 'credited_amount',
        key: 'credited_amount',
        width: '82px',
        align: 'right',
        render: (text, record) => {
          const currency = (record?.credited_currency || ':').split(':')[1];
          return `${Utils.trimAmount(record.credited_amount)} ${currency}`;
        },
        order: 16,
      },
	    {
		    title: '',
		    showSorterTooltip: false,
		    dataIndex: 'refund',
		    key: 'refund',
		    width: '82px',
		    align: 'center',
		    render: (text, record, index) => {
			    const isMerchantHasFinancePermission = profileStore.getMerchantPermissions[profileStore.getSelectedProfileId].includes('finance');
			    const isMerchantHasAdminPermission = profileStore.getMerchantPermissions[profileStore.getSelectedProfileId].includes('admin');

					return (
						((record.refund_supported || record.autorefund_supported) && record.kind === 'deposit' && record.state === 'done' && (isMerchantHasFinancePermission || isMerchantHasAdminPermission)) &&
							<Dropdown
								menu={{ items }}
								placement="bottomLeft"
								trigger={['click']}
								onClick={() => setSelectedTransactionInfo(record)}
							>
								<TableDropdownMenuContainer>
									<ReportTableMenuButton darkBorder={index % 2 === 1} />
								</TableDropdownMenuContainer>
							</Dropdown>
					)
		    },
		    order: 17,
	    },
    ],
    statistic: [
      {
        title: t('transaction.table.footer.date'),
        dataIndex: 'created_at',
        key: 'created_at',
        width: 100,
        sorter: (a, b) => compareAB(b.created_at, a.created_at),
        render: (text, record) => {
          const resultsBy = reportStore.getResultsByValue;
          let format = timeFormatByTimestamp(record.created_at, dayFormat);

          if (resultsBy === 'week') {
            format = timeFormatByTimestamp(record.created_at, weekFormat);
          }

          if (resultsBy === 'month') {
            format = timeFormatByTimestamp(record.created_at, monthFormat);
          }

          return <span>{format}</span>;
        },
      },
      {
        title: t('transaction.table.header.method'),
        dataIndex: 'payment_group',
        key: 'payment_group',
        width: 100,
        sorter: (a, b) => compareAB(b.payment_group, a.payment_group),
        render: (text, record) => record.payment_group,
      },
      {
        title: t('transaction.table.footer.nOfTrans'),
        dataIndex: 'n_transaction',
        key: 'n_transaction',
        width: 100,
        sorter: (a, b) => compareAB(b.n_transaction, a.n_transaction),
      },
      {
        title: t('transaction.table.footer.amountOfPayments'),
        dataIndex: 'amount_received',
        key: 'amount_received',
        width: 100,
        align: 'right',
        sorter: (a, b) => compareAB(b.amount, a.amount),
        render: (text, record) => Utils.trimAmount(record.amount),
      },
      {
        title: t('transaction.table.footer.fee'),
        dataIndex: 'commission_app',
        key: 'commission_app',
        width: 40,
        align: 'right',
        sorter: (a, b) => compareAB(b.commission_app, a.commission_app),
        render: (text, record) => Utils.trimAmount(record.commission_app),
      },
      {
        title: t('transaction.table.footer.averageBill'),
        dataIndex: 'average_bill',
        key: 'average_bill',
        width: 100,
        align: 'right',
        sorter: (a, b) => compareAB(b.average_bill, a.average_bill),
        render: (text, record) => Utils.trimAmount(record.average_bill),
      },
      {
        title: t('transaction.table.footer.conversion'),
        dataIndex: 'conversion',
        key: 'conversion',
        width: 100,
        sorter: (a, b) => compareAB(b.conversion, a.conversion),
      },
      {
        title: t('transaction.table.footer.successfulTransaction'),
        dataIndex: 'successed',
        key: 'successed',
        width: 120,
        sorter: (a, b) => compareAB(b.successed, a.successed),
      },
      {
        title: t('transaction.table.footer.declineTransaction'),
        dataIndex: 'canceled',
        key: 'canceled',
        width: 120,
        sorter: (a, b) => compareAB(b.canceled, a.canceled),
      },
    ],
    registry: [
      {
        title: t('transaction.table.registry.register'),
        dataIndex: 'label',
        key: 'label',
        width: '225px',
        render: (text, record) => (
          <TransactionRegistryDownloadTitle>
            {`registry_${record.label}.csv`}
          </TransactionRegistryDownloadTitle>
        ),
      },
      {
        title: '',
        dataIndex: 'date_from',
        key: 'date_from',
        render: (text, record) => (
          <TransactionRegistryDownloadLink
            onClick={() => downloadRegistryFileHandler(record)}
          >
            <TransactionRegistryDownloadLinkIconWrapper>
              <DownloadIcon color={theme.colors.mainColor} />
            </TransactionRegistryDownloadLinkIconWrapper>
            <TransactionRegistryDownloadLinkText>
              {t('transaction.table.registry.download')}
            </TransactionRegistryDownloadLinkText>
          </TransactionRegistryDownloadLink>
        ),
      }
    ],
    reports: [
      {
        title: t('transaction.table.registry.register'),
        dataIndex: 'label',
        key: 'label',
        width: '225px',
        render: (text, record) => (
          <TransactionRegistryDownloadTitle>
            {`registry_${record.label}.csv`}
          </TransactionRegistryDownloadTitle>
        ),
      },
      {
        title: '',
        dataIndex: 'date_from',
        key: 'date_from',
        render: (text, record) => (
          <TransactionRegistryDownloadLink
            onClick={() => downloadRegistryFileHandler(record)}
          >
            <TransactionRegistryDownloadLinkIconWrapper>
              <DownloadIcon color={theme.colors.iconColor} />
            </TransactionRegistryDownloadLinkIconWrapper>
            <TransactionRegistryDownloadLinkText>
              {t('transaction.table.registry.download')}
            </TransactionRegistryDownloadLinkText>
          </TransactionRegistryDownloadLink>
        ),
      }
    ],
  };

  /**
   * Calculate x scroll.
   */
  const calculateXscroll = () => {
    const data = reportStore.getTableType === 'volumes' ? columnTypeVolumes : columnsByType[getTableType];

    return data.reduce((acc, value) => {
      if (value.width) {
        return acc + value.width;
      }

      return acc;
    }, 0);
  };

  /**
   * Pagination change handler.
   * @param {number} page.
   * Number.
   */
  const paginationChangeHandler = (page) => {
    const offset = (page - 1) * transactionsPerPage;

    reportStore.setCurrentOffset(offset);
    reportStore.setCurrentPage(page);
  };

  /**
   * Pagination Prev, Next Buttons Render
   */
  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <TransactionsPaginationButton direction="prev">
          <PaginationPrevArrowIcon color={theme.colors.iconColor} /> {t('transaction.table.footer.button.prev')}
        </TransactionsPaginationButton>
      );
    }
    if (type === 'next') {
      return (
        <TransactionsPaginationButton direction="next">
          {t('transaction.table.footer.button.next')} <PaginationNextArrowIcon color={theme.colors.iconColor} />
        </TransactionsPaginationButton>
      );
    }
    return originalElement;
  };

  /**
   * Table footer render.
   */
  // const renderTableSummary = () => {
  //   if ('amount' in (generalReportStore.getTransactionsReportData?.total || {})) {
  //     const { amount, fee } = transactionsTotal;
  //     const { from, to } = reportStore.getPeriod;
  //     const dataLength = generalReportStore.getTransactionsReportData?.data?.length;
  //     const formatedFrom = timeFormatByTimestamp(from, dayFormat);
  //     const formatedTo = timeFormatByTimestamp(to, dayFormat);
  //
  //     let period = '';
  //     if (formatedFrom === formatedTo) {
  //       period = `${formatedFrom}`;
  //     } else {
  //       period = `${formatedFrom} - ${formatedTo}`;
  //     }
  //
  //     if (dataLength) {
  //       if (reportStore.getTableType === 'volumes') {
  //         return (
  //           <>
  //             <TableSummaryRow>
  //               <TableSummaryCell index={0}>{t('transaction.table.footer.total')}</TableSummaryCell>
  //               {Object.entries(transactionsTotal).map(([id, value]) => (
  //                 <TableSummaryCell index={1} key={id}>
  //                   {Utils.trimNumber(Number(value))}
  //                 </TableSummaryCell>
  //               ))}
  //             </TableSummaryRow>
  //           </>
  //         );
  //       }
  //
  //       return (
  //         <>
  //           <TableSummaryRow>
  //             <TableSummaryCell>{t('transaction.table.footer.total')}</TableSummaryCell>
  //             <TableSummaryTransCell colSpan={2}>
  //               <span>{t('transaction.table.footer.period')}</span> {period}
  //             </TableSummaryTransCell>
  //             <TableSummaryTransCell colSpan={reportStore.getTableType === 'statistic' ? 2 : 4} />
  //             <TableSummaryTransCell colSpan={2}>
  //               <span>{t('transaction.table.footer.amount')}</span>
  //               {Utils.trimNumber(amount, 3)
  //                 .toString()
  //                 .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //             </TableSummaryTransCell>
  //             <TableSummaryTransCell colSpan={2}>
  //               <span>{t('transaction.table.footer.fee')}</span>
  //               {Utils.trimNumber(fee, 3)
  //                 .toString()
  //                 .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //             </TableSummaryTransCell>
  //           </TableSummaryRow>
  //         </>
  //       );
  //     }
  //   }
  //
  //   return null;
  // };

  /**
   * On table change handler.
   * @param {object} pagination.
   * Pagination data.
   * @param {object} filters.
   * Filters data.
   * @param {object} sorter.
   * Sorter data.
   */
  const onTableChangeHandler = async (pagination, filters, sorter) => {
    const { field, order } = sorter;

    // Do not send request if items data length less than 'transactionsPerPage'.
    if (generalReportStore.getTransactionsReportData?.total?.n > transactionsPerPage) {
      if (order === undefined) {
        setOrderBy({});
      } else {
        setOrderBy({
          [field]: order.replace('end', '')
        });
      }
    }

    if (field === 'created_at') {
      const sortType = (order || 'ascend').replace('end', '').toUpperCase();
      setFieldsSortType({
        created_at: sortType,
      });
      generalReportStore.setTransactionsCreatedSortType(sortType);
      if (reportStore.getTableType !== TRANSACTIONS_VIEW_MODE.transactions) {
        await getMoreTransactions(true);
      }
    }
  };

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  const getMoreTransactions = async (root = false) => {
    try {
      const profileId = profileStore.getSelectedProfileId;
      setIsShowMoreBtnLoading(true);

      const {
        transactions: prevTransactions,
      } = generalReportStore.getTransactionsReportData;
      const {
        created_at: startedFrom,
      } = prevTransactions[prevTransactions.length - 1];
      const {
        from: selectedDateFrom,
        to: selectedDateTo,
      } = reportStore.getPeriod;

      if(reportStore.getTableType === TRANSACTIONS_VIEW_MODE.transactions) {
        const dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";
        const plusOneSecTimestamp = (startedFrom * 1000) + 1000;
        const date = fromUnixTime(Math.floor(plusOneSecTimestamp / 1000));
        const gmtOffsetMinutes = date.getTimezoneOffset(); // Get GMT offset in minutes
        const gmtDate = new Date(date.getTime() + gmtOffsetMinutes * 60 * 1000); // Apply GMT offset
        generalReportStore.setFilterParams({
          ...generalReportStore.filterParams,
          ...(!root && { start_from: format(gmtDate, dateFormat)}),
        });
        await generalReportStore.getTransactionsListV2(profileId);
      } else {
        const data = {
          date_range: {
            from: fromUnixTime(selectedDateFrom),
            to: fromUnixTime(selectedDateTo),
          },
          date_column: 'created_at',
          sort_by: fieldsSortType?.created_at || 'ASC',
          limit: transactionsPerPage,
          ...(!root && { start_from: fromUnixTime(startedFrom) }),
        };

        await generalReportStore.getTransactionsList(data, profileId);
      }

      setIsShowMoreBtnLoading(false);
    } catch (error) {
      setIsShowMoreBtnLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <TableColumnsSettings
            columnsList={reportStore.getTableType === 'volumes' ? columnTypeVolumes : columnsByType[getTableType]}
            setColumnsSettings={setColumnsSettings}
            tableTypeKey={getTableType}
          />
          <Table
            table-layout="fixed"
            // rowKey="id"
            columns={
              columnsSettingsHandler(reportStore.getTableType === 'volumes' ? columnTypeVolumes : columnsByType[getTableType], columnsSettings)
            }
            // components={vt}
            dataSource={transactionsList}
            pagination={false}
            // summary={(tableData) => renderTableSummary(tableData)}
            scroll={{ x: calculateXscroll() }}
            onChange={onTableChangeHandler}
            loading={reportStore.getIsTableLoading}
            className={`main-table tab-${reportStore.getTableType}`}
            rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            expandable={{
              expandedRowClassName: (_, index) => index % 2 === 0 ? 'expand-row-light' :  'expand-row-dark',
              expandedRowRender: (record, index) => <RefundTable data={record}/>,
              rowExpandable: record => !!record?.refunds?.payments?.length,
            }}
          />
          {(
            reportStore.getTableType === TRANSACTIONS_VIEW_MODE.transactions
            && generalReportStore.getTransactionsReportData?.show_more
          ) && (
            <ShowMoreButtonWrapperStyle>
              <ButtonElement
                onClick={() => getMoreTransactions()}
                loading={isShowMoreBtnLoading}
                clear="true"
              >
                Show more
              </ButtonElement>
            </ShowMoreButtonWrapperStyle>
          )}

          {reportStore.getTableType !== TRANSACTIONS_VIEW_MODE.transactions && (
            <PaginationStyle>
              <Pagination
                hideOnSinglePage
                onChange={paginationChangeHandler}
                defaultPageSize={transactionsPerPage}
                current={reportStore.getCurrentPage}
                total={paginationLength}
                showSizeChanger={false}
                itemRender={itemRender}
              />
            </PaginationStyle>
          )}
        </Col>
      </Row>
	    <RefundTransactionModal
	      isModalOpen={isTransactionRefundModalOpen}
	      setIsModalOpen={setIsTransactionRefundModalOpen}
	      selectedTransactionInfo={selectedTransactionInfo}
	      isEnableTwoFactorAuthVisible={isEnableTwoFactorAuthVisible}
	    />
    </>
  );
};

export default compose(
  withTranslation(),
  inject('generalReportStore', 'reportStore', 'profileStore')
)(observer(ReportsTable));
