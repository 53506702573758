/**
 * @file
 * Contains Sidebar component styles.
 */
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SidebarWrapper = styled.div``;

export const SidebarLogoWrapper = styled.div`
  background: ${(props) => props.theme.colors.extraLightGrey};
  margin-bottom: 10px;
  border-radius: 10px;
  //box-shadow: ${(props) => props.theme.shadow.box};
  position: relative;
  width: 100%;
  height: 84px;
  
  @media (min-width: 1400px) {
    height: 122px;
    margin-bottom: 22px;
  }

  a {
    font-style: normal;
    color: ${(props) => props.theme.colors.darkLight};
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 14px;
    font-weight: 700;

    svg {
      width: 100%;
    }
  }
`;

export const SidebarNavWrapper = styled.div`
  background: ${(props) => props.theme.colors.dark};
  border-radius: 10px;
  margin-bottom: 15px;
  //box-shadow: ${(props) => props.theme.shadow.box};

  a {
    display: block;
    position: relative;
    padding: 21px 0;
    
    @media (min-width: 1400px) {
      height: 104px;
      padding: 28px 0;
    }
    
    &:first-child {
      padding-top: 27px;
    }
  
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 27px;
    }
  }

  .active {
    opacity: 1;
    
    svg {
      path {
        fill: ${(props) => props.theme.colors.backgroundMain};
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 28px;
      height: 3px;
      //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      background: ${(props) => props.theme.colors.backgroundMain};
      transform: translateX(-50%);
      transition: width .5s ease;
      
      @media (min-width: 1400px) {
        bottom: 35px;
        width: 40px;
      }
    }
  }
`;

export const SidebarNavItem = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.extraLightGrey};
  //padding: 28px 0;
  
  svg {
    width: ${(props) => props.width ? `${props.width}px` : '25px'}!important;
    height: ${(props) => props.height ? `${props.height}px` : '25px'}!important;
  }
`;

export const SidebarSettingsWrapper = styled.div`
  border-radius: 10px;
  //box-shadow: ${(props) => props.theme.shadow.box};
  text-align: center;
  background: ${(props) => props.theme.colors.extraLightGrey};
  padding: 20px;
  margin-bottom: 15px;

  a {
    color: ${(props) => props.theme.colors.dark};
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .active {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      width: 28px;
      height: 3px;
      border-radius: 2px;
      background: ${(props) => props.theme.colors.dark};
      transform: translateX(-50%);
      transition: width .5s ease;
    }
  }
`;

export const SidebarSettingsItem = styled.div`
  display: flex;
  justify-content: center;
`;

export const SideBarLoginWrapper = styled.div`
  text-align: center;
  cursor: pointer;
`;

export const SidebarLoginItem = styled.div`
  display: flex;
  justify-content: center;
  svg {
    cursor: pointer;
  }
`;

export const LogoLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  
  svg {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    @media (min-width: 1400px) {
      top: calc(50% + 3px);
    }
  }
  
  @media (min-width: 1400px) {
    svg {
      height: 58px;
    }
  }
`;
