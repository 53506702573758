/**
 * @file
 * Contains SidebarNav component.
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import urls from '_common/routes/urls';
import { compose } from 'recompose';
import TooltipElement from '_common/components/Tooltip/TooltipElement';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import themeConfig from 'config';

import { kratosClient } from '../../services/apiClient.service';

import {
  SidebarLoginItem,
  SideBarLoginWrapper,
  SidebarNavItem,
  SidebarNavWrapper,
  SidebarSettingsItem,
  SidebarSettingsWrapper,
} from './Sidebar.style';
import LocalStorageService from '_common/services/localStorage.service';
import NavBarDashboardIcon from '_common/assets/renderSvgIcons/NavBarDashboardIcon';
import NavBarReportsIcon from '_common/assets/renderSvgIcons/NavBarReportsIcon';
import NavBarFinanceIcon from '_common/assets/renderSvgIcons/NavBarFinanceIcon';
import NavBarUsersIcon from '_common/assets/renderSvgIcons/NavBarUsersIcon';
import PermissionsGate from '../PermissionsGate/PermissionsGate';
import CustomizationIcon from '_common/assets/renderSvgIcons/CustomizationIcon';
import NavBarLogoutIcon from '../../assets/renderSvgIcons/NavBarLogoutIcon';

const SidebarNav = (props) => {
  const { t } = props;

  /**
   * App Theme
   */
  const { theme } = themeConfig[process.env.REACT_APP_THEME] || themeConfig.DEFAULT;

  /**
   * Logout.
   */
  const logout = async () => {
    LocalStorageService.deleteItem('user_permissions');
    LocalStorageService.deleteItem('selected_id');
    const res = await kratosClient.createBrowserLogoutFlow();
    if (res.data && res.data.logout_url) {
      window.location.replace(res.data.logout_url);
    }
  };

  return (
    <div>
      <SidebarNavWrapper>
        <TooltipElement trigger='hover' placement='right' title={t('sidebar.dashboard')}>
          <NavLink to={urls.dashboard}>
            <SidebarNavItem>
              <NavBarDashboardIcon color={theme.colors.navigationIconDisabledColor} />
            </SidebarNavItem>
          </NavLink>
        </TooltipElement>
        <PermissionsGate
          scopes={[
            'merchant.finances.dashboard.transactions.read',
            'merchant.finances.reports.download',
          ]}
        >
          <TooltipElement trigger='hover' placement='right' title={t('sidebar.reports')}>
            <NavLink to={`${urls.reports}?tab=transactions`}>
              <SidebarNavItem>
                <NavBarReportsIcon color={theme.colors.navigationIconDisabledColor} />
              </SidebarNavItem>
            </NavLink>
          </TooltipElement>
        </PermissionsGate>

        <PermissionsGate scopes={['merchant.finances.balances.read']}>
          <TooltipElement trigger='hover' placement='right' title={t('sidebar.finance')}>
            <NavLink to={urls.finance}>
              <SidebarNavItem>
                <NavBarFinanceIcon color={theme.colors.navigationIconDisabledColor} />
              </SidebarNavItem>
            </NavLink>
          </TooltipElement>
        </PermissionsGate>
	      <PermissionsGate scopes={['iam.read']}>
		      <TooltipElement trigger='hover' placement='right' title={t('sidebar.users')}>
			      <NavLink to={urls.users}>
				      <SidebarNavItem>
					      <NavBarUsersIcon color={theme.colors.navigationIconDisabledColor} />
				      </SidebarNavItem>
			      </NavLink>
		      </TooltipElement>
	      </PermissionsGate>
        {/*<TooltipElement trigger="hover" placement="right" title={t('sidebar.partners')}>*/}
        {/*  <NavLink to={urls.partners}>*/}
        {/*    <SidebarNavItem>*/}
        {/*      <PartnersIcon />*/}
        {/*    </SidebarNavItem>*/}
        {/*  </NavLink>*/}
        {/*</TooltipElement>*/}
      </SidebarNavWrapper>

      <SidebarSettingsWrapper>
        <TooltipElement trigger='hover' placement='right' title={t('sidebar.settings')}>
          <NavLink to={urls.settings}>
            <SidebarSettingsItem>
              <CustomizationIcon color={theme.colors.black} />
            </SidebarSettingsItem>
          </NavLink>
        </TooltipElement>
        {/*<TooltipElement trigger="hover" placement="right" title={t('sidebar.customization')}>*/}
        {/*  <Link to={urls.customization}>*/}
        {/*    <SidebarSettingsItem>*/}
        {/*      <CustomizationIcon color={theme.colors.iconColor} />*/}
        {/*    </SidebarSettingsItem>*/}
        {/*  </Link>*/}
        {/*</TooltipElement>*/}
        {/*<TooltipElement trigger="hover" placement="right" title={t('sidebar.settings')}>*/}
        {/*  <Link to={urls.settings}>*/}
        {/*    <SidebarSettingsItem>*/}
        {/*      <SettingsIcon color={theme.colors.iconColor} />*/}
        {/*    </SidebarSettingsItem>*/}
        {/*  </Link>*/}
        {/*</TooltipElement>*/}
        {/*<TooltipElement trigger="hover" placement="right" title={t('sidebar.help')}>*/}
        {/*  <Link to={urls.helpAndChat}>*/}
        {/*    <SidebarSettingsItem>*/}
        {/*      <HelpChatIcon color={theme.colors.iconColor} />*/}
        {/*    </SidebarSettingsItem>*/}
        {/*  </Link>*/}
        {/*</TooltipElement>*/}
      </SidebarSettingsWrapper>

      <SidebarSettingsWrapper>
        <TooltipElement placement='right' title={t('sidebar.logout')} trigger='hover'>
          <SideBarLoginWrapper onClick={logout}>
            <SidebarLoginItem>
              <NavBarLogoutIcon color={theme.colors.black} />
            </SidebarLoginItem>
          </SideBarLoginWrapper>
        </TooltipElement>
      </SidebarSettingsWrapper>
    </div>
  );
};

export default compose(withTranslation())(observer(SidebarNav));
