/**
 * @file
 * Contains Urls config.
 */

export default {
  // Public pages.
  public: {
    login: '/login',
    auth: '/auth',
    verification: '/auth/verification',
    recovery: '/auth/recovery',
    error: '/error',
  },

  newPassword: '/auth/recovery/newpassword',

  root: '/dashboard',

  // Dashboard page.
  dashboard: '/dashboard',

  // Users page.
  registrationIsSuccessfully: '/registration-is-successfully',

  // Users page.
  users: '/users',

  // Reports page.
  reports: '/reports',

  // Transactions page.
  finance: '/finance',

  // Setting page.
  settings: '/settings',

  // Transactions page.
  financeAccounts: '/finance/accounts',

  // Partners page.
  partners: '/partners',

  // Customization page
  customization: '/customization',

  // Help and chant page.
  helpAndChat: '/help-and-chat',

  // Forget password
  forgotPassword: '/forget-password',

  // Registration page.
  registration: '/registration',

  // Tempo password.
  tempo: '/default',

  // Privacy password.
  privacy: '/privacy',

  // Terms password.
  terms: '/terms-and-conditions',

  // Help password.
  help: '/help',

  // Questionnaire level link.
  questionnaire: '/questionnaire',

  // Kyc link.
  kyc: '/kyc',

  // Kyc levels link.
  kycLevels: '/kyc/levels',
};
