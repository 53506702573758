/**
 * @file
 * Contains Settings Component.
 */
import React, { useState, useEffect } from 'react';
import MainLayout from '_common/layouts/Main.layout';
import {
  Container,
	SettingsTabsContainer,
} from './Settings.style';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import IntegrationType from './IntegrationType';
import Security from './Security';
import usePermissions from '_common/hooks/usePermissions.hook';

const Settings = (props) => {
  const { profileStore, history } = props;

	/**
	 * Is write permission.
	 */
	const [isWritePermission] = usePermissions(['iam.write']);

	/**
	 * Is finance permission.
	 */
	const [isFinancePermissions] = usePermissions(['finance']);

  /**
   * Use translation.
   */
  const { t } = useTranslation();

	const settingsTabsItems = [
		{
			label: t('settings.title.integrationType'),
			key: 'integration-type',
			children: <IntegrationType />,
		},
		...(!!isWritePermission || !!isFinancePermissions) ? [
			{
				label: t('settings.title.security'),
				key: 'security',
				children: <Security />,
			}
		] : [],
	];

  return (
    <MainLayout>
      <Container>
	      <SettingsTabsContainer>
		      <Tabs
			      defaultActiveKey={history.location.state?.data}
						items={settingsTabsItems}
		      />
	      </SettingsTabsContainer>
      </Container>
    </MainLayout>
  );
};

export default inject('profileStore')(observer(Settings));

